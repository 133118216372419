/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ContentQuestionIdAnswerBody model module.
 * @module model/ContentQuestionIdAnswerBody
 * @version 1.0.0
 */
export class ContentQuestionIdAnswerBody {
  /**
   * Constructs a new <code>ContentQuestionIdAnswerBody</code>.
   * @alias module:model/ContentQuestionIdAnswerBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ContentQuestionIdAnswerBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ContentQuestionIdAnswerBody} obj Optional instance to populate.
   * @return {module:model/ContentQuestionIdAnswerBody} The populated <code>ContentQuestionIdAnswerBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ContentQuestionIdAnswerBody();
      if (data.hasOwnProperty('emailId'))
        obj.emailId = ApiClient.convertToType(data['emailId'], 'String');
      if (data.hasOwnProperty('selectedIndex'))
        obj.selectedIndex = ApiClient.convertToType(data['selectedIndex'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} emailId
 */
ContentQuestionIdAnswerBody.prototype.emailId = undefined;

/**
 * @member {Number} selectedIndex
 */
ContentQuestionIdAnswerBody.prototype.selectedIndex = undefined;

