/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {EspsBody} from '../model/EspsBody';
import {EspsEspIdBody} from '../model/EspsEspIdBody';
import {InlineResponse20011} from '../model/InlineResponse20011';
import {InlineResponse2018} from '../model/InlineResponse2018';

/**
* ESP service.
* @module api/ESPApi
* @version 1.0.0
*/
export class ESPApi {

    /**
    * Constructs a new ESPApi. 
    * @alias module:api/ESPApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addESP operation.
     * @callback moduleapi/ESPApi~addESPCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2018{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new esp
     * @param {module:model/EspsBody} body ESP
     * @param {module:api/ESPApi~addESPCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addESP(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addESP");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2018;

      return this.apiClient.callApi(
        '/esps', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteESP operation.
     * @callback moduleapi/ESPApi~deleteESPCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a esp
     * @param {String} espId ESP ID
     * @param {module:api/ESPApi~deleteESPCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteESP(espId, callback) {
      
      let postBody = null;
      // verify the required parameter 'espId' is set
      if (espId === undefined || espId === null) {
        throw new Error("Missing the required parameter 'espId' when calling deleteESP");
      }

      let pathParams = {
        'espId': espId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/esps/{espId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getESP operation.
     * @callback moduleapi/ESPApi~getESPCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2018{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a esp
     * @param {String} espId ESP ID
     * @param {module:api/ESPApi~getESPCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getESP(espId, callback) {
      
      let postBody = null;
      // verify the required parameter 'espId' is set
      if (espId === undefined || espId === null) {
        throw new Error("Missing the required parameter 'espId' when calling getESP");
      }

      let pathParams = {
        'espId': espId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2018;

      return this.apiClient.callApi(
        '/esps/{espId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getESPs operation.
     * @callback moduleapi/ESPApi~getESPsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20011{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get esps
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.type Type of esp
     * @param {String} opts.keyword Search keyword in title and description
     * @param {Number} opts.limit Number of esps
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/ESPApi~getESPsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getESPs(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'type': opts['type'],'keyword': opts['keyword'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20011;

      return this.apiClient.callApi(
        '/esps', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateESP operation.
     * @callback moduleapi/ESPApi~updateESPCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2018{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a esp
     * @param {module:model/EspsEspIdBody} body ESP
     * @param {String} espId ESP ID
     * @param {module:api/ESPApi~updateESPCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateESP(body, espId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateESP");
      }
      // verify the required parameter 'espId' is set
      if (espId === undefined || espId === null) {
        throw new Error("Missing the required parameter 'espId' when calling updateESP");
      }

      let pathParams = {
        'espId': espId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2018;

      return this.apiClient.callApi(
        '/esps/{espId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}