/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AutomationdataSettings} from './AutomationdataSettings';
import {DataAutomationDestination} from './DataAutomationDestination';

/**
 * The DataAutomation model module.
 * @module model/DataAutomation
 * @version 1.0.0
 */
export class DataAutomation {
  /**
   * Constructs a new <code>DataAutomation</code>.
   * @alias module:model/DataAutomation
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>DataAutomation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DataAutomation} obj Optional instance to populate.
   * @return {module:model/DataAutomation} The populated <code>DataAutomation</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DataAutomation();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('tags'))
        obj.tags = ApiClient.convertToType(data['tags'], ['String']);
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
      if (data.hasOwnProperty('query'))
        obj.query = ApiClient.convertToType(data['query'], 'String');
      if (data.hasOwnProperty('source'))
        obj.source = DataAutomationDestination.constructFromObject(data['source']);
      if (data.hasOwnProperty('destinations'))
        obj.destinations = ApiClient.convertToType(data['destinations'], [DataAutomationDestination]);
      if (data.hasOwnProperty('settings'))
        obj.settings = AutomationdataSettings.constructFromObject(data['settings']);
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
DataAutomation.prototype._id = undefined;

/**
 * @member {String} title
 */
DataAutomation.prototype.title = undefined;

/**
 * @member {String} description
 */
DataAutomation.prototype.description = undefined;

/**
 * @member {Array.<String>} tags
 */
DataAutomation.prototype.tags = undefined;

/**
 * @member {Boolean} enabled
 */
DataAutomation.prototype.enabled = undefined;

/**
 * @member {String} query
 */
DataAutomation.prototype.query = undefined;

/**
 * @member {module:model/DataAutomationDestination} source
 */
DataAutomation.prototype.source = undefined;

/**
 * @member {Array.<module:model/DataAutomationDestination>} destinations
 */
DataAutomation.prototype.destinations = undefined;

/**
 * @member {module:model/AutomationdataSettings} settings
 */
DataAutomation.prototype.settings = undefined;

/**
 * @member {String} created
 */
DataAutomation.prototype.created = undefined;

/**
 * @member {String} updated
 */
DataAutomation.prototype.updated = undefined;

