/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {ContentQuestionIdAnswerBody} from '../model/ContentQuestionIdAnswerBody';
import {InlineResponse20120} from '../model/InlineResponse20120';

/**
* Questions service.
* @module api/QuestionsApi
* @version 1.0.0
*/
export class QuestionsApi {

    /**
    * Constructs a new QuestionsApi. 
    * @alias module:api/QuestionsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the getQuestionAnswer operation.
     * @callback moduleapi/QuestionsApi~getQuestionAnswerCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20120{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a question answer
     * @param {String} propertyId Property ID
     * @param {String} contentQuestionId Content Question ID
     * @param {String} emailId Email ID
     * @param {module:api/QuestionsApi~getQuestionAnswerCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getQuestionAnswer(propertyId, contentQuestionId, emailId, callback) {
      
      let postBody = null;
      // verify the required parameter 'propertyId' is set
      if (propertyId === undefined || propertyId === null) {
        throw new Error("Missing the required parameter 'propertyId' when calling getQuestionAnswer");
      }
      // verify the required parameter 'contentQuestionId' is set
      if (contentQuestionId === undefined || contentQuestionId === null) {
        throw new Error("Missing the required parameter 'contentQuestionId' when calling getQuestionAnswer");
      }
      // verify the required parameter 'emailId' is set
      if (emailId === undefined || emailId === null) {
        throw new Error("Missing the required parameter 'emailId' when calling getQuestionAnswer");
      }

      let pathParams = {
        'propertyId': propertyId,'contentQuestionId': contentQuestionId,'emailId': emailId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20120;

      return this.apiClient.callApi(
        '/contents/properties/{propertyId}/questions/{contentQuestionId}/answer/{emailId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the postQuestionAnswer operation.
     * @callback moduleapi/QuestionsApi~postQuestionAnswerCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20120{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Submit a question answer
     * @param {module:model/ContentQuestionIdAnswerBody} body Parameters
     * @param {String} propertyId Property ID
     * @param {String} contentQuestionId Content Question ID
     * @param {module:api/QuestionsApi~postQuestionAnswerCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    postQuestionAnswer(body, propertyId, contentQuestionId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling postQuestionAnswer");
      }
      // verify the required parameter 'propertyId' is set
      if (propertyId === undefined || propertyId === null) {
        throw new Error("Missing the required parameter 'propertyId' when calling postQuestionAnswer");
      }
      // verify the required parameter 'contentQuestionId' is set
      if (contentQuestionId === undefined || contentQuestionId === null) {
        throw new Error("Missing the required parameter 'contentQuestionId' when calling postQuestionAnswer");
      }

      let pathParams = {
        'propertyId': propertyId,'contentQuestionId': contentQuestionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20120;

      return this.apiClient.callApi(
        '/contents/properties/{propertyId}/questions/{contentQuestionId}/answer', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}