/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AutomationtriggersactionsData} from './AutomationtriggersactionsData';
import {AutomationtriggersactionsParams} from './AutomationtriggersactionsParams';

/**
 * The TriggersActionsBody model module.
 * @module model/TriggersActionsBody
 * @version 1.0.0
 */
export class TriggersActionsBody {
  /**
   * Constructs a new <code>TriggersActionsBody</code>.
   * @alias module:model/TriggersActionsBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>TriggersActionsBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TriggersActionsBody} obj Optional instance to populate.
   * @return {module:model/TriggersActionsBody} The populated <code>TriggersActionsBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TriggersActionsBody();
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
      if (data.hasOwnProperty('params'))
        obj.params = ApiClient.convertToType(data['params'], [AutomationtriggersactionsParams]);
      if (data.hasOwnProperty('data'))
        obj.data = AutomationtriggersactionsData.constructFromObject(data['data']);
    }
    return obj;
  }
}

/**
 * @member {String} title
 */
TriggersActionsBody.prototype.title = undefined;

/**
 * @member {String} description
 */
TriggersActionsBody.prototype.description = undefined;

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
TriggersActionsBody.TypeEnum = {
  /**
   * value: "send-email"
   * @const
   */
  send_email: "send-email"
};
/**
 * @member {module:model/TriggersActionsBody.TypeEnum} type
 */
TriggersActionsBody.prototype.type = undefined;

/**
 * @member {Boolean} enabled
 */
TriggersActionsBody.prototype.enabled = undefined;

/**
 * @member {Array.<module:model/AutomationtriggersactionsParams>} params
 */
TriggersActionsBody.prototype.params = undefined;

/**
 * @member {module:model/AutomationtriggersactionsData} data
 */
TriggersActionsBody.prototype.data = undefined;

