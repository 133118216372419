/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {DataPipelinesBody} from '../model/DataPipelinesBody';
import {InlineResponse20025} from '../model/InlineResponse20025';
import {InlineResponse20026} from '../model/InlineResponse20026';
import {InlineResponse20118} from '../model/InlineResponse20118';
import {InlineResponse20119} from '../model/InlineResponse20119';
import {PipelinesDataAutomationPipelineIdBody} from '../model/PipelinesDataAutomationPipelineIdBody';

/**
* DataAutomationPipeline service.
* @module api/DataAutomationPipelineApi
* @version 1.0.0
*/
export class DataAutomationPipelineApi {

    /**
    * Constructs a new DataAutomationPipelineApi. 
    * @alias module:api/DataAutomationPipelineApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addDataAutomationPipeline operation.
     * @callback moduleapi/DataAutomationPipelineApi~addDataAutomationPipelineCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20118{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new Data Automation Pipeline
     * @param {module:model/DataPipelinesBody} body Data Automation Pipeline
     * @param {module:api/DataAutomationPipelineApi~addDataAutomationPipelineCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addDataAutomationPipeline(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addDataAutomationPipeline");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20118;

      return this.apiClient.callApi(
        '/automation/data/pipelines', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteDataAutomationPipeline operation.
     * @callback moduleapi/DataAutomationPipelineApi~deleteDataAutomationPipelineCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a Data Automation Pipeline
     * @param {String} dataAutomationPipelineId Data Automation Pipeline ID
     * @param {module:api/DataAutomationPipelineApi~deleteDataAutomationPipelineCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteDataAutomationPipeline(dataAutomationPipelineId, callback) {
      
      let postBody = null;
      // verify the required parameter 'dataAutomationPipelineId' is set
      if (dataAutomationPipelineId === undefined || dataAutomationPipelineId === null) {
        throw new Error("Missing the required parameter 'dataAutomationPipelineId' when calling deleteDataAutomationPipeline");
      }

      let pathParams = {
        'dataAutomationPipelineId': dataAutomationPipelineId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/automation/data/pipelines/{dataAutomationPipelineId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getDataAutomationPipeline operation.
     * @callback moduleapi/DataAutomationPipelineApi~getDataAutomationPipelineCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20118{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a Data Automation Pipeline
     * @param {String} dataAutomationPipelineId Data Automation Pipeline ID
     * @param {module:api/DataAutomationPipelineApi~getDataAutomationPipelineCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getDataAutomationPipeline(dataAutomationPipelineId, callback) {
      
      let postBody = null;
      // verify the required parameter 'dataAutomationPipelineId' is set
      if (dataAutomationPipelineId === undefined || dataAutomationPipelineId === null) {
        throw new Error("Missing the required parameter 'dataAutomationPipelineId' when calling getDataAutomationPipeline");
      }

      let pathParams = {
        'dataAutomationPipelineId': dataAutomationPipelineId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20118;

      return this.apiClient.callApi(
        '/automation/data/pipelines/{dataAutomationPipelineId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getDataAutomationPipelineRuns operation.
     * @callback moduleapi/DataAutomationPipelineApi~getDataAutomationPipelineRunsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20026{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Data Automation Pipeline runs
     * @param {String} dataAutomationPipelineId Data Automation Pipeline id
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.state State
     * @param {Number} opts.limit Number of Data Automation Pipeline runs
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/DataAutomationPipelineApi~getDataAutomationPipelineRunsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getDataAutomationPipelineRuns(dataAutomationPipelineId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'dataAutomationPipelineId' is set
      if (dataAutomationPipelineId === undefined || dataAutomationPipelineId === null) {
        throw new Error("Missing the required parameter 'dataAutomationPipelineId' when calling getDataAutomationPipelineRuns");
      }

      let pathParams = {
        'dataAutomationPipelineId': dataAutomationPipelineId
      };
      let queryParams = {
        'state': opts['state'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20026;

      return this.apiClient.callApi(
        '/automation/data/pipelines/{dataAutomationPipelineId}/runs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getDataAutomationPipelines operation.
     * @callback moduleapi/DataAutomationPipelineApi~getDataAutomationPipelinesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20025{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Data Automation Pipelines
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyword Keyword search in title and description
     * @param {Number} opts.limit Number of Data Automation Pipelines
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/DataAutomationPipelineApi~getDataAutomationPipelinesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getDataAutomationPipelines(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'keyword': opts['keyword'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20025;

      return this.apiClient.callApi(
        '/automation/data/pipelines', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getRecentDataAutomationPipelineRuns operation.
     * @callback moduleapi/DataAutomationPipelineApi~getRecentDataAutomationPipelineRunsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20026{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get recent Data Automation Pipeline runs
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.dataAutomationPipelineIds Data Automation Pipeline Ids
     * @param {module:api/DataAutomationPipelineApi~getRecentDataAutomationPipelineRunsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getRecentDataAutomationPipelineRuns(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'dataAutomationPipelineIds': this.apiClient.buildCollectionParam(opts['dataAutomationPipelineIds'], 'csv')
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20026;

      return this.apiClient.callApi(
        '/automation/data/pipelines/runs/recent', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the runDataAutomationPipeline operation.
     * @callback moduleapi/DataAutomationPipelineApi~runDataAutomationPipelineCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20119{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Run a Data Automation Pipeline
     * @param {String} dataAutomationPipelineId Data Automation Pipeline ID
     * @param {module:api/DataAutomationPipelineApi~runDataAutomationPipelineCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    runDataAutomationPipeline(dataAutomationPipelineId, callback) {
      
      let postBody = null;
      // verify the required parameter 'dataAutomationPipelineId' is set
      if (dataAutomationPipelineId === undefined || dataAutomationPipelineId === null) {
        throw new Error("Missing the required parameter 'dataAutomationPipelineId' when calling runDataAutomationPipeline");
      }

      let pathParams = {
        'dataAutomationPipelineId': dataAutomationPipelineId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20119;

      return this.apiClient.callApi(
        '/automation/data/pipelines/{dataAutomationPipelineId}/run', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateDataAutomationPipeline operation.
     * @callback moduleapi/DataAutomationPipelineApi~updateDataAutomationPipelineCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20118{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a Data Automation Pipeline
     * @param {module:model/PipelinesDataAutomationPipelineIdBody} body Data Automation Pipeline
     * @param {String} dataAutomationPipelineId Data Automation Pipeline ID
     * @param {module:api/DataAutomationPipelineApi~updateDataAutomationPipelineCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateDataAutomationPipeline(body, dataAutomationPipelineId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateDataAutomationPipeline");
      }
      // verify the required parameter 'dataAutomationPipelineId' is set
      if (dataAutomationPipelineId === undefined || dataAutomationPipelineId === null) {
        throw new Error("Missing the required parameter 'dataAutomationPipelineId' when calling updateDataAutomationPipeline");
      }

      let pathParams = {
        'dataAutomationPipelineId': dataAutomationPipelineId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20118;

      return this.apiClient.callApi(
        '/automation/data/pipelines/{dataAutomationPipelineId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}