/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EmailProfile model module.
 * @module model/EmailProfile
 * @version 1.0.0
 */
export class EmailProfile {
  /**
   * Constructs a new <code>EmailProfile</code>.
   * @alias module:model/EmailProfile
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailProfile</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailProfile} obj Optional instance to populate.
   * @return {module:model/EmailProfile} The populated <code>EmailProfile</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailProfile();
      if (data.hasOwnProperty('firstName'))
        obj.firstName = ApiClient.convertToType(data['firstName'], 'String');
      if (data.hasOwnProperty('lastName'))
        obj.lastName = ApiClient.convertToType(data['lastName'], 'String');
      if (data.hasOwnProperty('address1'))
        obj.address1 = ApiClient.convertToType(data['address1'], 'String');
      if (data.hasOwnProperty('address2'))
        obj.address2 = ApiClient.convertToType(data['address2'], 'String');
      if (data.hasOwnProperty('city'))
        obj.city = ApiClient.convertToType(data['city'], 'String');
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'String');
      if (data.hasOwnProperty('zip'))
        obj.zip = ApiClient.convertToType(data['zip'], 'String');
      if (data.hasOwnProperty('country'))
        obj.country = ApiClient.convertToType(data['country'], 'String');
      if (data.hasOwnProperty('phone'))
        obj.phone = ApiClient.convertToType(data['phone'], 'String');
      if (data.hasOwnProperty('gender'))
        obj.gender = ApiClient.convertToType(data['gender'], 'String');
      if (data.hasOwnProperty('dateOfBirth'))
        obj.dateOfBirth = ApiClient.convertToType(data['dateOfBirth'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} firstName
 */
EmailProfile.prototype.firstName = undefined;

/**
 * @member {String} lastName
 */
EmailProfile.prototype.lastName = undefined;

/**
 * @member {String} address1
 */
EmailProfile.prototype.address1 = undefined;

/**
 * @member {String} address2
 */
EmailProfile.prototype.address2 = undefined;

/**
 * @member {String} city
 */
EmailProfile.prototype.city = undefined;

/**
 * @member {String} state
 */
EmailProfile.prototype.state = undefined;

/**
 * @member {String} zip
 */
EmailProfile.prototype.zip = undefined;

/**
 * @member {String} country
 */
EmailProfile.prototype.country = undefined;

/**
 * @member {String} phone
 */
EmailProfile.prototype.phone = undefined;

/**
 * Allowed values for the <code>gender</code> property.
 * @enum {String}
 * @readonly
 */
EmailProfile.GenderEnum = {
  /**
   * value: "male"
   * @const
   */
  male: "male",

  /**
   * value: "female"
   * @const
   */
  female: "female"
};
/**
 * @member {module:model/EmailProfile.GenderEnum} gender
 */
EmailProfile.prototype.gender = undefined;

/**
 * @member {String} dateOfBirth
 */
EmailProfile.prototype.dateOfBirth = undefined;

