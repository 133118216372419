/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EmailActivitySubscriptionOption model module.
 * @module model/EmailActivitySubscriptionOption
 * @version 1.0.0
 */
export class EmailActivitySubscriptionOption {
  /**
   * Constructs a new <code>EmailActivitySubscriptionOption</code>.
   * @alias module:model/EmailActivitySubscriptionOption
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailActivitySubscriptionOption</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailActivitySubscriptionOption} obj Optional instance to populate.
   * @return {module:model/EmailActivitySubscriptionOption} The populated <code>EmailActivitySubscriptionOption</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailActivitySubscriptionOption();
      if (data.hasOwnProperty('key'))
        obj.key = ApiClient.convertToType(data['key'], 'String');
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} key
 */
EmailActivitySubscriptionOption.prototype.key = undefined;

/**
 * @member {Boolean} enabled
 */
EmailActivitySubscriptionOption.prototype.enabled = undefined;

