/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from './ApiClient';
import {ActionsTriggerAutomationActionIdBody} from './model/ActionsTriggerAutomationActionIdBody';
import {ActivitiesEmailActivityIdBody} from './model/ActivitiesEmailActivityIdBody';
import {AutomationDataBody} from './model/AutomationDataBody';
import {AutomationTriggersBody} from './model/AutomationTriggersBody';
import {AutomationdataSettings} from './model/AutomationdataSettings';
import {AutomationdataSource} from './model/AutomationdataSource';
import {AutomationdataSourceParams} from './model/AutomationdataSourceParams';
import {AutomationdataconnectionsParams} from './model/AutomationdataconnectionsParams';
import {AutomationtriggersactionsData} from './model/AutomationtriggersactionsData';
import {AutomationtriggersactionsParams} from './model/AutomationtriggersactionsParams';
import {Campaign} from './model/Campaign';
import {CampaignIdRunBody} from './model/CampaignIdRunBody';
import {CampaignIdTestBody} from './model/CampaignIdTestBody';
import {CampaignRun} from './model/CampaignRun';
import {CampaignRunData} from './model/CampaignRunData';
import {CampaignRunDataSend} from './model/CampaignRunDataSend';
import {CampaignRunResult} from './model/CampaignRunResult';
import {CampaignRunStartDates} from './model/CampaignRunStartDates';
import {CampaignRunStat} from './model/CampaignRunStat';
import {CampaignSegments} from './model/CampaignSegments';
import {CampaignSettings} from './model/CampaignSettings';
import {CampaignTemplates} from './model/CampaignTemplates';
import {CampaignTemplatesInner} from './model/CampaignTemplatesInner';
import {CampaignsBody} from './model/CampaignsBody';
import {CampaignsCampaignIdBody} from './model/CampaignsCampaignIdBody';
import {CampaignsSegments} from './model/CampaignsSegments';
import {CampaignsTemplates} from './model/CampaignsTemplates';
import {ConnectionsDataAutomationConnectionIdBody} from './model/ConnectionsDataAutomationConnectionIdBody';
import {ContentQuestionIdAnswerBody} from './model/ContentQuestionIdAnswerBody';
import {DataAutomation} from './model/DataAutomation';
import {DataAutomationConnection} from './model/DataAutomationConnection';
import {DataAutomationDestination} from './model/DataAutomationDestination';
import {DataAutomationPipeline} from './model/DataAutomationPipeline';
import {DataAutomationPipelineRun} from './model/DataAutomationPipelineRun';
import {DataAutomationRun} from './model/DataAutomationRun';
import {DataAutomationRunResult} from './model/DataAutomationRunResult';
import {DataConnectionsBody} from './model/DataConnectionsBody';
import {DataDataAutomationIdBody} from './model/DataDataAutomationIdBody';
import {DataPipelinesBody} from './model/DataPipelinesBody';
import {Domain} from './model/Domain';
import {DomainWeb} from './model/DomainWeb';
import {DomainWebAlternates} from './model/DomainWebAlternates';
import {ESP} from './model/ESP';
import {ESPSettings} from './model/ESPSettings';
import {ESPSettingsAccess} from './model/ESPSettingsAccess';
import {ESPSettingsCustom} from './model/ESPSettingsCustom';
import {ESPSettingsDomains} from './model/ESPSettingsDomains';
import {ESPSettingsEmails} from './model/ESPSettingsEmails';
import {Email} from './model/Email';
import {EmailAct} from './model/EmailAct';
import {EmailActivity} from './model/EmailActivity';
import {EmailActivityStatus} from './model/EmailActivityStatus';
import {EmailActivitySubscription} from './model/EmailActivitySubscription';
import {EmailActivitySubscriptionOption} from './model/EmailActivitySubscriptionOption';
import {EmailActivitySubscriptionSource} from './model/EmailActivitySubscriptionSource';
import {EmailIdActivitiesBody} from './model/EmailIdActivitiesBody';
import {EmailProfile} from './model/EmailProfile';
import {EmailProvider} from './model/EmailProvider';
import {EmailVerification} from './model/EmailVerification';
import {EmailsBody} from './model/EmailsBody';
import {EmailsCheck} from './model/EmailsCheck';
import {EmailsCheckFieldsMap} from './model/EmailsCheckFieldsMap';
import {EmailsCheckResult} from './model/EmailsCheckResult';
import {EmailsCheckResultData} from './model/EmailsCheckResultData';
import {EmailsCheckResultFiles} from './model/EmailsCheckResultFiles';
import {EmailsCheckSettings} from './model/EmailsCheckSettings';
import {EmailsCheckSettingsActivity} from './model/EmailsCheckSettingsActivity';
import {EmailsCheckSettingsImport} from './model/EmailsCheckSettingsImport';
import {EmailsCheckSettingsImportEmails} from './model/EmailsCheckSettingsImportEmails';
import {EmailsChecksBody} from './model/EmailsChecksBody';
import {EmailsEmailIdBody} from './model/EmailsEmailIdBody';
import {EmailsImport} from './model/EmailsImport';
import {EmailsImportResult} from './model/EmailsImportResult';
import {EmailsImportResultData} from './model/EmailsImportResultData';
import {EmailsImportsBody} from './model/EmailsImportsBody';
import {EmailsSubscribeBody} from './model/EmailsSubscribeBody';
import {EmailsSuppressBody} from './model/EmailsSuppressBody';
import {EmailsUnsubscribeBody} from './model/EmailsUnsubscribeBody';
import {EspsBody} from './model/EspsBody';
import {EspsEspIdBody} from './model/EspsEspIdBody';
import {EventsTriggerAutomationEventIdBody} from './model/EventsTriggerAutomationEventIdBody';
import {FileType} from './model/FileType';
import {InlineResponse200} from './model/InlineResponse200';
import {InlineResponse2001} from './model/InlineResponse2001';
import {InlineResponse20010} from './model/InlineResponse20010';
import {InlineResponse20011} from './model/InlineResponse20011';
import {InlineResponse20012} from './model/InlineResponse20012';
import {InlineResponse20013} from './model/InlineResponse20013';
import {InlineResponse20013Data} from './model/InlineResponse20013Data';
import {InlineResponse20014} from './model/InlineResponse20014';
import {InlineResponse20015} from './model/InlineResponse20015';
import {InlineResponse20016} from './model/InlineResponse20016';
import {InlineResponse20017} from './model/InlineResponse20017';
import {InlineResponse20018} from './model/InlineResponse20018';
import {InlineResponse20019} from './model/InlineResponse20019';
import {InlineResponse2002} from './model/InlineResponse2002';
import {InlineResponse20020} from './model/InlineResponse20020';
import {InlineResponse20021} from './model/InlineResponse20021';
import {InlineResponse20022} from './model/InlineResponse20022';
import {InlineResponse20023} from './model/InlineResponse20023';
import {InlineResponse20024} from './model/InlineResponse20024';
import {InlineResponse20025} from './model/InlineResponse20025';
import {InlineResponse20026} from './model/InlineResponse20026';
import {InlineResponse20027} from './model/InlineResponse20027';
import {InlineResponse2003} from './model/InlineResponse2003';
import {InlineResponse2004} from './model/InlineResponse2004';
import {InlineResponse2005} from './model/InlineResponse2005';
import {InlineResponse2006} from './model/InlineResponse2006';
import {InlineResponse2007} from './model/InlineResponse2007';
import {InlineResponse2008} from './model/InlineResponse2008';
import {InlineResponse2008Data} from './model/InlineResponse2008Data';
import {InlineResponse2009} from './model/InlineResponse2009';
import {InlineResponse201} from './model/InlineResponse201';
import {InlineResponse2011} from './model/InlineResponse2011';
import {InlineResponse20110} from './model/InlineResponse20110';
import {InlineResponse20111} from './model/InlineResponse20111';
import {InlineResponse20112} from './model/InlineResponse20112';
import {InlineResponse20113} from './model/InlineResponse20113';
import {InlineResponse20114} from './model/InlineResponse20114';
import {InlineResponse20115} from './model/InlineResponse20115';
import {InlineResponse20116} from './model/InlineResponse20116';
import {InlineResponse20117} from './model/InlineResponse20117';
import {InlineResponse20118} from './model/InlineResponse20118';
import {InlineResponse20119} from './model/InlineResponse20119';
import {InlineResponse2012} from './model/InlineResponse2012';
import {InlineResponse20120} from './model/InlineResponse20120';
import {InlineResponse2013} from './model/InlineResponse2013';
import {InlineResponse2014} from './model/InlineResponse2014';
import {InlineResponse2015} from './model/InlineResponse2015';
import {InlineResponse2016} from './model/InlineResponse2016';
import {InlineResponse2017} from './model/InlineResponse2017';
import {InlineResponse2018} from './model/InlineResponse2018';
import {InlineResponse2019} from './model/InlineResponse2019';
import {KeysPartnerBody} from './model/KeysPartnerBody';
import {KeysWebBody} from './model/KeysWebBody';
import {Notification} from './model/Notification';
import {NotificationAlert} from './model/NotificationAlert';
import {PartnerKey} from './model/PartnerKey';
import {PartnerKeyIdBody} from './model/PartnerKeyIdBody';
import {PipelinesDataAutomationPipelineIdBody} from './model/PipelinesDataAutomationPipelineIdBody';
import {PropertiesBody} from './model/PropertiesBody';
import {PropertiesPropertyIdBody} from './model/PropertiesPropertyIdBody';
import {Property} from './model/Property';
import {PropertyEmailOption} from './model/PropertyEmailOption';
import {PropertyOptions} from './model/PropertyOptions';
import {QuestionAnswer} from './model/QuestionAnswer';
import {QuestionAnswerAnswer} from './model/QuestionAnswerAnswer';
import {Segment} from './model/Segment';
import {SegmentsBody} from './model/SegmentsBody';
import {SegmentsJoin} from './model/SegmentsJoin';
import {SegmentsJoinOn} from './model/SegmentsJoinOn';
import {SegmentsSegmentIdBody} from './model/SegmentsSegmentIdBody';
import {Template} from './model/Template';
import {TemplatesBody} from './model/TemplatesBody';
import {TemplatesContent} from './model/TemplatesContent';
import {TemplatesTemplateIdBody} from './model/TemplatesTemplateIdBody';
import {TriggerAutomation} from './model/TriggerAutomation';
import {TriggerAutomationAction} from './model/TriggerAutomationAction';
import {TriggerAutomationEvent} from './model/TriggerAutomationEvent';
import {TriggersActionsBody} from './model/TriggersActionsBody';
import {TriggersEventsBody} from './model/TriggersEventsBody';
import {TriggersTriggerAutomationIdBody} from './model/TriggersTriggerAutomationIdBody';
import {User} from './model/User';
import {UserProfile} from './model/UserProfile';
import {UsersBody} from './model/UsersBody';
import {UsersCreateBody} from './model/UsersCreateBody';
import {UsersLoginBody} from './model/UsersLoginBody';
import {UsersUserIdBody} from './model/UsersUserIdBody';
import {WebKey} from './model/WebKey';
import {WebKeyIdBody} from './model/WebKeyIdBody';
import {CampaignApi} from './api/CampaignApi';
import {DataAutomationApi} from './api/DataAutomationApi';
import {DataAutomationConnectionApi} from './api/DataAutomationConnectionApi';
import {DataAutomationPipelineApi} from './api/DataAutomationPipelineApi';
import {ESPApi} from './api/ESPApi';
import {EmailApi} from './api/EmailApi';
import {EmailActivityApi} from './api/EmailActivityApi';
import {NotificationApi} from './api/NotificationApi';
import {PartnerKeyApi} from './api/PartnerKeyApi';
import {PropertyApi} from './api/PropertyApi';
import {QuestionsApi} from './api/QuestionsApi';
import {SegmentApi} from './api/SegmentApi';
import {TemplateApi} from './api/TemplateApi';
import {TriggerAutomationApi} from './api/TriggerAutomationApi';
import {TriggerAutomationActionApi} from './api/TriggerAutomationActionApi';
import {TriggerAutomationEventApi} from './api/TriggerAutomationEventApi';
import {UserApi} from './api/UserApi';
import {WebKeyApi} from './api/WebKeyApi';

/**
* Object.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var MarketingApi = require('index'); // See note below*.
* var xxxSvc = new MarketingApi.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new MarketingApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new MarketingApi.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new MarketingApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version 1.0.0
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The ActionsTriggerAutomationActionIdBody model constructor.
     * @property {module:model/ActionsTriggerAutomationActionIdBody}
     */
    ActionsTriggerAutomationActionIdBody,

    /**
     * The ActivitiesEmailActivityIdBody model constructor.
     * @property {module:model/ActivitiesEmailActivityIdBody}
     */
    ActivitiesEmailActivityIdBody,

    /**
     * The AutomationDataBody model constructor.
     * @property {module:model/AutomationDataBody}
     */
    AutomationDataBody,

    /**
     * The AutomationTriggersBody model constructor.
     * @property {module:model/AutomationTriggersBody}
     */
    AutomationTriggersBody,

    /**
     * The AutomationdataSettings model constructor.
     * @property {module:model/AutomationdataSettings}
     */
    AutomationdataSettings,

    /**
     * The AutomationdataSource model constructor.
     * @property {module:model/AutomationdataSource}
     */
    AutomationdataSource,

    /**
     * The AutomationdataSourceParams model constructor.
     * @property {module:model/AutomationdataSourceParams}
     */
    AutomationdataSourceParams,

    /**
     * The AutomationdataconnectionsParams model constructor.
     * @property {module:model/AutomationdataconnectionsParams}
     */
    AutomationdataconnectionsParams,

    /**
     * The AutomationtriggersactionsData model constructor.
     * @property {module:model/AutomationtriggersactionsData}
     */
    AutomationtriggersactionsData,

    /**
     * The AutomationtriggersactionsParams model constructor.
     * @property {module:model/AutomationtriggersactionsParams}
     */
    AutomationtriggersactionsParams,

    /**
     * The Campaign model constructor.
     * @property {module:model/Campaign}
     */
    Campaign,

    /**
     * The CampaignIdRunBody model constructor.
     * @property {module:model/CampaignIdRunBody}
     */
    CampaignIdRunBody,

    /**
     * The CampaignIdTestBody model constructor.
     * @property {module:model/CampaignIdTestBody}
     */
    CampaignIdTestBody,

    /**
     * The CampaignRun model constructor.
     * @property {module:model/CampaignRun}
     */
    CampaignRun,

    /**
     * The CampaignRunData model constructor.
     * @property {module:model/CampaignRunData}
     */
    CampaignRunData,

    /**
     * The CampaignRunDataSend model constructor.
     * @property {module:model/CampaignRunDataSend}
     */
    CampaignRunDataSend,

    /**
     * The CampaignRunResult model constructor.
     * @property {module:model/CampaignRunResult}
     */
    CampaignRunResult,

    /**
     * The CampaignRunStartDates model constructor.
     * @property {module:model/CampaignRunStartDates}
     */
    CampaignRunStartDates,

    /**
     * The CampaignRunStat model constructor.
     * @property {module:model/CampaignRunStat}
     */
    CampaignRunStat,

    /**
     * The CampaignSegments model constructor.
     * @property {module:model/CampaignSegments}
     */
    CampaignSegments,

    /**
     * The CampaignSettings model constructor.
     * @property {module:model/CampaignSettings}
     */
    CampaignSettings,

    /**
     * The CampaignTemplates model constructor.
     * @property {module:model/CampaignTemplates}
     */
    CampaignTemplates,

    /**
     * The CampaignTemplatesInner model constructor.
     * @property {module:model/CampaignTemplatesInner}
     */
    CampaignTemplatesInner,

    /**
     * The CampaignsBody model constructor.
     * @property {module:model/CampaignsBody}
     */
    CampaignsBody,

    /**
     * The CampaignsCampaignIdBody model constructor.
     * @property {module:model/CampaignsCampaignIdBody}
     */
    CampaignsCampaignIdBody,

    /**
     * The CampaignsSegments model constructor.
     * @property {module:model/CampaignsSegments}
     */
    CampaignsSegments,

    /**
     * The CampaignsTemplates model constructor.
     * @property {module:model/CampaignsTemplates}
     */
    CampaignsTemplates,

    /**
     * The ConnectionsDataAutomationConnectionIdBody model constructor.
     * @property {module:model/ConnectionsDataAutomationConnectionIdBody}
     */
    ConnectionsDataAutomationConnectionIdBody,

    /**
     * The ContentQuestionIdAnswerBody model constructor.
     * @property {module:model/ContentQuestionIdAnswerBody}
     */
    ContentQuestionIdAnswerBody,

    /**
     * The DataAutomation model constructor.
     * @property {module:model/DataAutomation}
     */
    DataAutomation,

    /**
     * The DataAutomationConnection model constructor.
     * @property {module:model/DataAutomationConnection}
     */
    DataAutomationConnection,

    /**
     * The DataAutomationDestination model constructor.
     * @property {module:model/DataAutomationDestination}
     */
    DataAutomationDestination,

    /**
     * The DataAutomationPipeline model constructor.
     * @property {module:model/DataAutomationPipeline}
     */
    DataAutomationPipeline,

    /**
     * The DataAutomationPipelineRun model constructor.
     * @property {module:model/DataAutomationPipelineRun}
     */
    DataAutomationPipelineRun,

    /**
     * The DataAutomationRun model constructor.
     * @property {module:model/DataAutomationRun}
     */
    DataAutomationRun,

    /**
     * The DataAutomationRunResult model constructor.
     * @property {module:model/DataAutomationRunResult}
     */
    DataAutomationRunResult,

    /**
     * The DataConnectionsBody model constructor.
     * @property {module:model/DataConnectionsBody}
     */
    DataConnectionsBody,

    /**
     * The DataDataAutomationIdBody model constructor.
     * @property {module:model/DataDataAutomationIdBody}
     */
    DataDataAutomationIdBody,

    /**
     * The DataPipelinesBody model constructor.
     * @property {module:model/DataPipelinesBody}
     */
    DataPipelinesBody,

    /**
     * The Domain model constructor.
     * @property {module:model/Domain}
     */
    Domain,

    /**
     * The DomainWeb model constructor.
     * @property {module:model/DomainWeb}
     */
    DomainWeb,

    /**
     * The DomainWebAlternates model constructor.
     * @property {module:model/DomainWebAlternates}
     */
    DomainWebAlternates,

    /**
     * The ESP model constructor.
     * @property {module:model/ESP}
     */
    ESP,

    /**
     * The ESPSettings model constructor.
     * @property {module:model/ESPSettings}
     */
    ESPSettings,

    /**
     * The ESPSettingsAccess model constructor.
     * @property {module:model/ESPSettingsAccess}
     */
    ESPSettingsAccess,

    /**
     * The ESPSettingsCustom model constructor.
     * @property {module:model/ESPSettingsCustom}
     */
    ESPSettingsCustom,

    /**
     * The ESPSettingsDomains model constructor.
     * @property {module:model/ESPSettingsDomains}
     */
    ESPSettingsDomains,

    /**
     * The ESPSettingsEmails model constructor.
     * @property {module:model/ESPSettingsEmails}
     */
    ESPSettingsEmails,

    /**
     * The Email model constructor.
     * @property {module:model/Email}
     */
    Email,

    /**
     * The EmailAct model constructor.
     * @property {module:model/EmailAct}
     */
    EmailAct,

    /**
     * The EmailActivity model constructor.
     * @property {module:model/EmailActivity}
     */
    EmailActivity,

    /**
     * The EmailActivityStatus model constructor.
     * @property {module:model/EmailActivityStatus}
     */
    EmailActivityStatus,

    /**
     * The EmailActivitySubscription model constructor.
     * @property {module:model/EmailActivitySubscription}
     */
    EmailActivitySubscription,

    /**
     * The EmailActivitySubscriptionOption model constructor.
     * @property {module:model/EmailActivitySubscriptionOption}
     */
    EmailActivitySubscriptionOption,

    /**
     * The EmailActivitySubscriptionSource model constructor.
     * @property {module:model/EmailActivitySubscriptionSource}
     */
    EmailActivitySubscriptionSource,

    /**
     * The EmailIdActivitiesBody model constructor.
     * @property {module:model/EmailIdActivitiesBody}
     */
    EmailIdActivitiesBody,

    /**
     * The EmailProfile model constructor.
     * @property {module:model/EmailProfile}
     */
    EmailProfile,

    /**
     * The EmailProvider model constructor.
     * @property {module:model/EmailProvider}
     */
    EmailProvider,

    /**
     * The EmailVerification model constructor.
     * @property {module:model/EmailVerification}
     */
    EmailVerification,

    /**
     * The EmailsBody model constructor.
     * @property {module:model/EmailsBody}
     */
    EmailsBody,

    /**
     * The EmailsCheck model constructor.
     * @property {module:model/EmailsCheck}
     */
    EmailsCheck,

    /**
     * The EmailsCheckFieldsMap model constructor.
     * @property {module:model/EmailsCheckFieldsMap}
     */
    EmailsCheckFieldsMap,

    /**
     * The EmailsCheckResult model constructor.
     * @property {module:model/EmailsCheckResult}
     */
    EmailsCheckResult,

    /**
     * The EmailsCheckResultData model constructor.
     * @property {module:model/EmailsCheckResultData}
     */
    EmailsCheckResultData,

    /**
     * The EmailsCheckResultFiles model constructor.
     * @property {module:model/EmailsCheckResultFiles}
     */
    EmailsCheckResultFiles,

    /**
     * The EmailsCheckSettings model constructor.
     * @property {module:model/EmailsCheckSettings}
     */
    EmailsCheckSettings,

    /**
     * The EmailsCheckSettingsActivity model constructor.
     * @property {module:model/EmailsCheckSettingsActivity}
     */
    EmailsCheckSettingsActivity,

    /**
     * The EmailsCheckSettingsImport model constructor.
     * @property {module:model/EmailsCheckSettingsImport}
     */
    EmailsCheckSettingsImport,

    /**
     * The EmailsCheckSettingsImportEmails model constructor.
     * @property {module:model/EmailsCheckSettingsImportEmails}
     */
    EmailsCheckSettingsImportEmails,

    /**
     * The EmailsChecksBody model constructor.
     * @property {module:model/EmailsChecksBody}
     */
    EmailsChecksBody,

    /**
     * The EmailsEmailIdBody model constructor.
     * @property {module:model/EmailsEmailIdBody}
     */
    EmailsEmailIdBody,

    /**
     * The EmailsImport model constructor.
     * @property {module:model/EmailsImport}
     */
    EmailsImport,

    /**
     * The EmailsImportResult model constructor.
     * @property {module:model/EmailsImportResult}
     */
    EmailsImportResult,

    /**
     * The EmailsImportResultData model constructor.
     * @property {module:model/EmailsImportResultData}
     */
    EmailsImportResultData,

    /**
     * The EmailsImportsBody model constructor.
     * @property {module:model/EmailsImportsBody}
     */
    EmailsImportsBody,

    /**
     * The EmailsSubscribeBody model constructor.
     * @property {module:model/EmailsSubscribeBody}
     */
    EmailsSubscribeBody,

    /**
     * The EmailsSuppressBody model constructor.
     * @property {module:model/EmailsSuppressBody}
     */
    EmailsSuppressBody,

    /**
     * The EmailsUnsubscribeBody model constructor.
     * @property {module:model/EmailsUnsubscribeBody}
     */
    EmailsUnsubscribeBody,

    /**
     * The EspsBody model constructor.
     * @property {module:model/EspsBody}
     */
    EspsBody,

    /**
     * The EspsEspIdBody model constructor.
     * @property {module:model/EspsEspIdBody}
     */
    EspsEspIdBody,

    /**
     * The EventsTriggerAutomationEventIdBody model constructor.
     * @property {module:model/EventsTriggerAutomationEventIdBody}
     */
    EventsTriggerAutomationEventIdBody,

    /**
     * The FileType model constructor.
     * @property {module:model/FileType}
     */
    FileType,

    /**
     * The InlineResponse200 model constructor.
     * @property {module:model/InlineResponse200}
     */
    InlineResponse200,

    /**
     * The InlineResponse2001 model constructor.
     * @property {module:model/InlineResponse2001}
     */
    InlineResponse2001,

    /**
     * The InlineResponse20010 model constructor.
     * @property {module:model/InlineResponse20010}
     */
    InlineResponse20010,

    /**
     * The InlineResponse20011 model constructor.
     * @property {module:model/InlineResponse20011}
     */
    InlineResponse20011,

    /**
     * The InlineResponse20012 model constructor.
     * @property {module:model/InlineResponse20012}
     */
    InlineResponse20012,

    /**
     * The InlineResponse20013 model constructor.
     * @property {module:model/InlineResponse20013}
     */
    InlineResponse20013,

    /**
     * The InlineResponse20013Data model constructor.
     * @property {module:model/InlineResponse20013Data}
     */
    InlineResponse20013Data,

    /**
     * The InlineResponse20014 model constructor.
     * @property {module:model/InlineResponse20014}
     */
    InlineResponse20014,

    /**
     * The InlineResponse20015 model constructor.
     * @property {module:model/InlineResponse20015}
     */
    InlineResponse20015,

    /**
     * The InlineResponse20016 model constructor.
     * @property {module:model/InlineResponse20016}
     */
    InlineResponse20016,

    /**
     * The InlineResponse20017 model constructor.
     * @property {module:model/InlineResponse20017}
     */
    InlineResponse20017,

    /**
     * The InlineResponse20018 model constructor.
     * @property {module:model/InlineResponse20018}
     */
    InlineResponse20018,

    /**
     * The InlineResponse20019 model constructor.
     * @property {module:model/InlineResponse20019}
     */
    InlineResponse20019,

    /**
     * The InlineResponse2002 model constructor.
     * @property {module:model/InlineResponse2002}
     */
    InlineResponse2002,

    /**
     * The InlineResponse20020 model constructor.
     * @property {module:model/InlineResponse20020}
     */
    InlineResponse20020,

    /**
     * The InlineResponse20021 model constructor.
     * @property {module:model/InlineResponse20021}
     */
    InlineResponse20021,

    /**
     * The InlineResponse20022 model constructor.
     * @property {module:model/InlineResponse20022}
     */
    InlineResponse20022,

    /**
     * The InlineResponse20023 model constructor.
     * @property {module:model/InlineResponse20023}
     */
    InlineResponse20023,

    /**
     * The InlineResponse20024 model constructor.
     * @property {module:model/InlineResponse20024}
     */
    InlineResponse20024,

    /**
     * The InlineResponse20025 model constructor.
     * @property {module:model/InlineResponse20025}
     */
    InlineResponse20025,

    /**
     * The InlineResponse20026 model constructor.
     * @property {module:model/InlineResponse20026}
     */
    InlineResponse20026,

    /**
     * The InlineResponse20027 model constructor.
     * @property {module:model/InlineResponse20027}
     */
    InlineResponse20027,

    /**
     * The InlineResponse2003 model constructor.
     * @property {module:model/InlineResponse2003}
     */
    InlineResponse2003,

    /**
     * The InlineResponse2004 model constructor.
     * @property {module:model/InlineResponse2004}
     */
    InlineResponse2004,

    /**
     * The InlineResponse2005 model constructor.
     * @property {module:model/InlineResponse2005}
     */
    InlineResponse2005,

    /**
     * The InlineResponse2006 model constructor.
     * @property {module:model/InlineResponse2006}
     */
    InlineResponse2006,

    /**
     * The InlineResponse2007 model constructor.
     * @property {module:model/InlineResponse2007}
     */
    InlineResponse2007,

    /**
     * The InlineResponse2008 model constructor.
     * @property {module:model/InlineResponse2008}
     */
    InlineResponse2008,

    /**
     * The InlineResponse2008Data model constructor.
     * @property {module:model/InlineResponse2008Data}
     */
    InlineResponse2008Data,

    /**
     * The InlineResponse2009 model constructor.
     * @property {module:model/InlineResponse2009}
     */
    InlineResponse2009,

    /**
     * The InlineResponse201 model constructor.
     * @property {module:model/InlineResponse201}
     */
    InlineResponse201,

    /**
     * The InlineResponse2011 model constructor.
     * @property {module:model/InlineResponse2011}
     */
    InlineResponse2011,

    /**
     * The InlineResponse20110 model constructor.
     * @property {module:model/InlineResponse20110}
     */
    InlineResponse20110,

    /**
     * The InlineResponse20111 model constructor.
     * @property {module:model/InlineResponse20111}
     */
    InlineResponse20111,

    /**
     * The InlineResponse20112 model constructor.
     * @property {module:model/InlineResponse20112}
     */
    InlineResponse20112,

    /**
     * The InlineResponse20113 model constructor.
     * @property {module:model/InlineResponse20113}
     */
    InlineResponse20113,

    /**
     * The InlineResponse20114 model constructor.
     * @property {module:model/InlineResponse20114}
     */
    InlineResponse20114,

    /**
     * The InlineResponse20115 model constructor.
     * @property {module:model/InlineResponse20115}
     */
    InlineResponse20115,

    /**
     * The InlineResponse20116 model constructor.
     * @property {module:model/InlineResponse20116}
     */
    InlineResponse20116,

    /**
     * The InlineResponse20117 model constructor.
     * @property {module:model/InlineResponse20117}
     */
    InlineResponse20117,

    /**
     * The InlineResponse20118 model constructor.
     * @property {module:model/InlineResponse20118}
     */
    InlineResponse20118,

    /**
     * The InlineResponse20119 model constructor.
     * @property {module:model/InlineResponse20119}
     */
    InlineResponse20119,

    /**
     * The InlineResponse2012 model constructor.
     * @property {module:model/InlineResponse2012}
     */
    InlineResponse2012,

    /**
     * The InlineResponse20120 model constructor.
     * @property {module:model/InlineResponse20120}
     */
    InlineResponse20120,

    /**
     * The InlineResponse2013 model constructor.
     * @property {module:model/InlineResponse2013}
     */
    InlineResponse2013,

    /**
     * The InlineResponse2014 model constructor.
     * @property {module:model/InlineResponse2014}
     */
    InlineResponse2014,

    /**
     * The InlineResponse2015 model constructor.
     * @property {module:model/InlineResponse2015}
     */
    InlineResponse2015,

    /**
     * The InlineResponse2016 model constructor.
     * @property {module:model/InlineResponse2016}
     */
    InlineResponse2016,

    /**
     * The InlineResponse2017 model constructor.
     * @property {module:model/InlineResponse2017}
     */
    InlineResponse2017,

    /**
     * The InlineResponse2018 model constructor.
     * @property {module:model/InlineResponse2018}
     */
    InlineResponse2018,

    /**
     * The InlineResponse2019 model constructor.
     * @property {module:model/InlineResponse2019}
     */
    InlineResponse2019,

    /**
     * The KeysPartnerBody model constructor.
     * @property {module:model/KeysPartnerBody}
     */
    KeysPartnerBody,

    /**
     * The KeysWebBody model constructor.
     * @property {module:model/KeysWebBody}
     */
    KeysWebBody,

    /**
     * The Notification model constructor.
     * @property {module:model/Notification}
     */
    Notification,

    /**
     * The NotificationAlert model constructor.
     * @property {module:model/NotificationAlert}
     */
    NotificationAlert,

    /**
     * The PartnerKey model constructor.
     * @property {module:model/PartnerKey}
     */
    PartnerKey,

    /**
     * The PartnerKeyIdBody model constructor.
     * @property {module:model/PartnerKeyIdBody}
     */
    PartnerKeyIdBody,

    /**
     * The PipelinesDataAutomationPipelineIdBody model constructor.
     * @property {module:model/PipelinesDataAutomationPipelineIdBody}
     */
    PipelinesDataAutomationPipelineIdBody,

    /**
     * The PropertiesBody model constructor.
     * @property {module:model/PropertiesBody}
     */
    PropertiesBody,

    /**
     * The PropertiesPropertyIdBody model constructor.
     * @property {module:model/PropertiesPropertyIdBody}
     */
    PropertiesPropertyIdBody,

    /**
     * The Property model constructor.
     * @property {module:model/Property}
     */
    Property,

    /**
     * The PropertyEmailOption model constructor.
     * @property {module:model/PropertyEmailOption}
     */
    PropertyEmailOption,

    /**
     * The PropertyOptions model constructor.
     * @property {module:model/PropertyOptions}
     */
    PropertyOptions,

    /**
     * The QuestionAnswer model constructor.
     * @property {module:model/QuestionAnswer}
     */
    QuestionAnswer,

    /**
     * The QuestionAnswerAnswer model constructor.
     * @property {module:model/QuestionAnswerAnswer}
     */
    QuestionAnswerAnswer,

    /**
     * The Segment model constructor.
     * @property {module:model/Segment}
     */
    Segment,

    /**
     * The SegmentsBody model constructor.
     * @property {module:model/SegmentsBody}
     */
    SegmentsBody,

    /**
     * The SegmentsJoin model constructor.
     * @property {module:model/SegmentsJoin}
     */
    SegmentsJoin,

    /**
     * The SegmentsJoinOn model constructor.
     * @property {module:model/SegmentsJoinOn}
     */
    SegmentsJoinOn,

    /**
     * The SegmentsSegmentIdBody model constructor.
     * @property {module:model/SegmentsSegmentIdBody}
     */
    SegmentsSegmentIdBody,

    /**
     * The Template model constructor.
     * @property {module:model/Template}
     */
    Template,

    /**
     * The TemplatesBody model constructor.
     * @property {module:model/TemplatesBody}
     */
    TemplatesBody,

    /**
     * The TemplatesContent model constructor.
     * @property {module:model/TemplatesContent}
     */
    TemplatesContent,

    /**
     * The TemplatesTemplateIdBody model constructor.
     * @property {module:model/TemplatesTemplateIdBody}
     */
    TemplatesTemplateIdBody,

    /**
     * The TriggerAutomation model constructor.
     * @property {module:model/TriggerAutomation}
     */
    TriggerAutomation,

    /**
     * The TriggerAutomationAction model constructor.
     * @property {module:model/TriggerAutomationAction}
     */
    TriggerAutomationAction,

    /**
     * The TriggerAutomationEvent model constructor.
     * @property {module:model/TriggerAutomationEvent}
     */
    TriggerAutomationEvent,

    /**
     * The TriggersActionsBody model constructor.
     * @property {module:model/TriggersActionsBody}
     */
    TriggersActionsBody,

    /**
     * The TriggersEventsBody model constructor.
     * @property {module:model/TriggersEventsBody}
     */
    TriggersEventsBody,

    /**
     * The TriggersTriggerAutomationIdBody model constructor.
     * @property {module:model/TriggersTriggerAutomationIdBody}
     */
    TriggersTriggerAutomationIdBody,

    /**
     * The User model constructor.
     * @property {module:model/User}
     */
    User,

    /**
     * The UserProfile model constructor.
     * @property {module:model/UserProfile}
     */
    UserProfile,

    /**
     * The UsersBody model constructor.
     * @property {module:model/UsersBody}
     */
    UsersBody,

    /**
     * The UsersCreateBody model constructor.
     * @property {module:model/UsersCreateBody}
     */
    UsersCreateBody,

    /**
     * The UsersLoginBody model constructor.
     * @property {module:model/UsersLoginBody}
     */
    UsersLoginBody,

    /**
     * The UsersUserIdBody model constructor.
     * @property {module:model/UsersUserIdBody}
     */
    UsersUserIdBody,

    /**
     * The WebKey model constructor.
     * @property {module:model/WebKey}
     */
    WebKey,

    /**
     * The WebKeyIdBody model constructor.
     * @property {module:model/WebKeyIdBody}
     */
    WebKeyIdBody,

    /**
    * The CampaignApi service constructor.
    * @property {module:api/CampaignApi}
    */
    CampaignApi,

    /**
    * The DataAutomationApi service constructor.
    * @property {module:api/DataAutomationApi}
    */
    DataAutomationApi,

    /**
    * The DataAutomationConnectionApi service constructor.
    * @property {module:api/DataAutomationConnectionApi}
    */
    DataAutomationConnectionApi,

    /**
    * The DataAutomationPipelineApi service constructor.
    * @property {module:api/DataAutomationPipelineApi}
    */
    DataAutomationPipelineApi,

    /**
    * The ESPApi service constructor.
    * @property {module:api/ESPApi}
    */
    ESPApi,

    /**
    * The EmailApi service constructor.
    * @property {module:api/EmailApi}
    */
    EmailApi,

    /**
    * The EmailActivityApi service constructor.
    * @property {module:api/EmailActivityApi}
    */
    EmailActivityApi,

    /**
    * The NotificationApi service constructor.
    * @property {module:api/NotificationApi}
    */
    NotificationApi,

    /**
    * The PartnerKeyApi service constructor.
    * @property {module:api/PartnerKeyApi}
    */
    PartnerKeyApi,

    /**
    * The PropertyApi service constructor.
    * @property {module:api/PropertyApi}
    */
    PropertyApi,

    /**
    * The QuestionsApi service constructor.
    * @property {module:api/QuestionsApi}
    */
    QuestionsApi,

    /**
    * The SegmentApi service constructor.
    * @property {module:api/SegmentApi}
    */
    SegmentApi,

    /**
    * The TemplateApi service constructor.
    * @property {module:api/TemplateApi}
    */
    TemplateApi,

    /**
    * The TriggerAutomationApi service constructor.
    * @property {module:api/TriggerAutomationApi}
    */
    TriggerAutomationApi,

    /**
    * The TriggerAutomationActionApi service constructor.
    * @property {module:api/TriggerAutomationActionApi}
    */
    TriggerAutomationActionApi,

    /**
    * The TriggerAutomationEventApi service constructor.
    * @property {module:api/TriggerAutomationEventApi}
    */
    TriggerAutomationEventApi,

    /**
    * The UserApi service constructor.
    * @property {module:api/UserApi}
    */
    UserApi,

    /**
    * The WebKeyApi service constructor.
    * @property {module:api/WebKeyApi}
    */
    WebKeyApi
};
