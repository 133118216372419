/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {EmailActivitySubscriptionOption} from './EmailActivitySubscriptionOption';
import {EmailActivitySubscriptionSource} from './EmailActivitySubscriptionSource';

/**
 * The EmailActivitySubscription model module.
 * @module model/EmailActivitySubscription
 * @version 1.0.0
 */
export class EmailActivitySubscription {
  /**
   * Constructs a new <code>EmailActivitySubscription</code>.
   * @alias module:model/EmailActivitySubscription
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailActivitySubscription</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailActivitySubscription} obj Optional instance to populate.
   * @return {module:model/EmailActivitySubscription} The populated <code>EmailActivitySubscription</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailActivitySubscription();
      if (data.hasOwnProperty('subscribed'))
        obj.subscribed = ApiClient.convertToType(data['subscribed'], 'Boolean');
      if (data.hasOwnProperty('date'))
        obj.date = ApiClient.convertToType(data['date'], 'String');
      if (data.hasOwnProperty('source'))
        obj.source = EmailActivitySubscriptionSource.constructFromObject(data['source']);
      if (data.hasOwnProperty('options'))
        obj.options = ApiClient.convertToType(data['options'], [EmailActivitySubscriptionOption]);
    }
    return obj;
  }
}

/**
 * @member {Boolean} subscribed
 */
EmailActivitySubscription.prototype.subscribed = undefined;

/**
 * @member {String} date
 */
EmailActivitySubscription.prototype.date = undefined;

/**
 * @member {module:model/EmailActivitySubscriptionSource} source
 */
EmailActivitySubscription.prototype.source = undefined;

/**
 * @member {Array.<module:model/EmailActivitySubscriptionOption>} options
 */
EmailActivitySubscription.prototype.options = undefined;

