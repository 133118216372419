/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Email} from './Email';
import {EmailActivityStatus} from './EmailActivityStatus';
import {EmailActivitySubscription} from './EmailActivitySubscription';
import {Property} from './Property';

/**
 * The EmailActivity model module.
 * @module model/EmailActivity
 * @version 1.0.0
 */
export class EmailActivity {
  /**
   * Constructs a new <code>EmailActivity</code>.
   * @alias module:model/EmailActivity
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailActivity</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailActivity} obj Optional instance to populate.
   * @return {module:model/EmailActivity} The populated <code>EmailActivity</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailActivity();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('email'))
        obj.email = Email.constructFromObject(data['email']);
      if (data.hasOwnProperty('property'))
        obj.property = Property.constructFromObject(data['property']);
      if (data.hasOwnProperty('status'))
        obj.status = EmailActivityStatus.constructFromObject(data['status']);
      if (data.hasOwnProperty('subscription'))
        obj.subscription = EmailActivitySubscription.constructFromObject(data['subscription']);
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
EmailActivity.prototype._id = undefined;

/**
 * @member {module:model/Email} email
 */
EmailActivity.prototype.email = undefined;

/**
 * @member {module:model/Property} property
 */
EmailActivity.prototype.property = undefined;

/**
 * @member {module:model/EmailActivityStatus} status
 */
EmailActivity.prototype.status = undefined;

/**
 * @member {module:model/EmailActivitySubscription} subscription
 */
EmailActivity.prototype.subscription = undefined;

/**
 * @member {String} created
 */
EmailActivity.prototype.created = undefined;

/**
 * @member {String} updated
 */
EmailActivity.prototype.updated = undefined;

