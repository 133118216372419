/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The CampaignRunStat model module.
 * @module model/CampaignRunStat
 * @version 1.0.0
 */
export class CampaignRunStat {
  /**
   * Constructs a new <code>CampaignRunStat</code>.
   * @alias module:model/CampaignRunStat
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CampaignRunStat</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CampaignRunStat} obj Optional instance to populate.
   * @return {module:model/CampaignRunStat} The populated <code>CampaignRunStat</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CampaignRunStat();
      if (data.hasOwnProperty('sendDate'))
        obj.sendDate = ApiClient.convertToType(data['sendDate'], 'String');
      if (data.hasOwnProperty('propertyId'))
        obj.propertyId = ApiClient.convertToType(data['propertyId'], 'String');
      if (data.hasOwnProperty('propertyTitle'))
        obj.propertyTitle = ApiClient.convertToType(data['propertyTitle'], 'String');
      if (data.hasOwnProperty('campaignId'))
        obj.campaignId = ApiClient.convertToType(data['campaignId'], 'String');
      if (data.hasOwnProperty('campaignTitle'))
        obj.campaignTitle = ApiClient.convertToType(data['campaignTitle'], 'String');
      if (data.hasOwnProperty('campaignRunId'))
        obj.campaignRunId = ApiClient.convertToType(data['campaignRunId'], 'String');
      if (data.hasOwnProperty('templateId'))
        obj.templateId = ApiClient.convertToType(data['templateId'], 'String');
      if (data.hasOwnProperty('templateTitle'))
        obj.templateTitle = ApiClient.convertToType(data['templateTitle'], 'String');
      if (data.hasOwnProperty('sends'))
        obj.sends = ApiClient.convertToType(data['sends'], 'Number');
      if (data.hasOwnProperty('deliveries'))
        obj.deliveries = ApiClient.convertToType(data['deliveries'], 'Number');
      if (data.hasOwnProperty('uniqueOpens'))
        obj.uniqueOpens = ApiClient.convertToType(data['uniqueOpens'], 'Number');
      if (data.hasOwnProperty('opens'))
        obj.opens = ApiClient.convertToType(data['opens'], 'Number');
      if (data.hasOwnProperty('uniqueClicks'))
        obj.uniqueClicks = ApiClient.convertToType(data['uniqueClicks'], 'Number');
      if (data.hasOwnProperty('clicks'))
        obj.clicks = ApiClient.convertToType(data['clicks'], 'Number');
      if (data.hasOwnProperty('softBounces'))
        obj.softBounces = ApiClient.convertToType(data['softBounces'], 'Number');
      if (data.hasOwnProperty('hardBounces'))
        obj.hardBounces = ApiClient.convertToType(data['hardBounces'], 'Number');
      if (data.hasOwnProperty('complained'))
        obj.complained = ApiClient.convertToType(data['complained'], 'Number');
      if (data.hasOwnProperty('unsubscribed'))
        obj.unsubscribed = ApiClient.convertToType(data['unsubscribed'], 'Number');
      if (data.hasOwnProperty('rejected'))
        obj.rejected = ApiClient.convertToType(data['rejected'], 'Number');
      if (data.hasOwnProperty('error'))
        obj.error = ApiClient.convertToType(data['error'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} sendDate
 */
CampaignRunStat.prototype.sendDate = undefined;

/**
 * @member {String} propertyId
 */
CampaignRunStat.prototype.propertyId = undefined;

/**
 * @member {String} propertyTitle
 */
CampaignRunStat.prototype.propertyTitle = undefined;

/**
 * @member {String} campaignId
 */
CampaignRunStat.prototype.campaignId = undefined;

/**
 * @member {String} campaignTitle
 */
CampaignRunStat.prototype.campaignTitle = undefined;

/**
 * @member {String} campaignRunId
 */
CampaignRunStat.prototype.campaignRunId = undefined;

/**
 * @member {String} templateId
 */
CampaignRunStat.prototype.templateId = undefined;

/**
 * @member {String} templateTitle
 */
CampaignRunStat.prototype.templateTitle = undefined;

/**
 * @member {Number} sends
 */
CampaignRunStat.prototype.sends = undefined;

/**
 * @member {Number} deliveries
 */
CampaignRunStat.prototype.deliveries = undefined;

/**
 * @member {Number} uniqueOpens
 */
CampaignRunStat.prototype.uniqueOpens = undefined;

/**
 * @member {Number} opens
 */
CampaignRunStat.prototype.opens = undefined;

/**
 * @member {Number} uniqueClicks
 */
CampaignRunStat.prototype.uniqueClicks = undefined;

/**
 * @member {Number} clicks
 */
CampaignRunStat.prototype.clicks = undefined;

/**
 * @member {Number} softBounces
 */
CampaignRunStat.prototype.softBounces = undefined;

/**
 * @member {Number} hardBounces
 */
CampaignRunStat.prototype.hardBounces = undefined;

/**
 * @member {Number} complained
 */
CampaignRunStat.prototype.complained = undefined;

/**
 * @member {Number} unsubscribed
 */
CampaignRunStat.prototype.unsubscribed = undefined;

/**
 * @member {Number} rejected
 */
CampaignRunStat.prototype.rejected = undefined;

/**
 * @member {Number} error
 */
CampaignRunStat.prototype.error = undefined;

