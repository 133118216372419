/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AutomationdataSettings model module.
 * @module model/AutomationdataSettings
 * @version 1.0.0
 */
export class AutomationdataSettings {
  /**
   * Constructs a new <code>AutomationdataSettings</code>.
   * @alias module:model/AutomationdataSettings
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AutomationdataSettings</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AutomationdataSettings} obj Optional instance to populate.
   * @return {module:model/AutomationdataSettings} The populated <code>AutomationdataSettings</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AutomationdataSettings();
      if (data.hasOwnProperty('startDate'))
        obj.startDate = ApiClient.convertToType(data['startDate'], 'String');
      if (data.hasOwnProperty('schedule'))
        obj.schedule = ApiClient.convertToType(data['schedule'], 'String');
      if (data.hasOwnProperty('nextDate'))
        obj.nextDate = ApiClient.convertToType(data['nextDate'], 'String');
      if (data.hasOwnProperty('endDate'))
        obj.endDate = ApiClient.convertToType(data['endDate'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} startDate
 */
AutomationdataSettings.prototype.startDate = undefined;

/**
 * @member {String} schedule
 */
AutomationdataSettings.prototype.schedule = undefined;

/**
 * @member {String} nextDate
 */
AutomationdataSettings.prototype.nextDate = undefined;

/**
 * @member {String} endDate
 */
AutomationdataSettings.prototype.endDate = undefined;

