/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EmailsImportResultData model module.
 * @module model/EmailsImportResultData
 * @version 1.0.0
 */
export class EmailsImportResultData {
  /**
   * Constructs a new <code>EmailsImportResultData</code>.
   * @alias module:model/EmailsImportResultData
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailsImportResultData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailsImportResultData} obj Optional instance to populate.
   * @return {module:model/EmailsImportResultData} The populated <code>EmailsImportResultData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailsImportResultData();
      if (data.hasOwnProperty('upserted'))
        obj.upserted = ApiClient.convertToType(data['upserted'], 'Number');
      if (data.hasOwnProperty('matched'))
        obj.matched = ApiClient.convertToType(data['matched'], 'Number');
      if (data.hasOwnProperty('modified'))
        obj.modified = ApiClient.convertToType(data['modified'], 'Number');
      if (data.hasOwnProperty('invalid'))
        obj.invalid = ApiClient.convertToType(data['invalid'], 'Number');
      if (data.hasOwnProperty('errors'))
        obj.errors = ApiClient.convertToType(data['errors'], ['String']);
    }
    return obj;
  }
}

/**
 * @member {Number} upserted
 */
EmailsImportResultData.prototype.upserted = undefined;

/**
 * @member {Number} matched
 */
EmailsImportResultData.prototype.matched = undefined;

/**
 * @member {Number} modified
 */
EmailsImportResultData.prototype.modified = undefined;

/**
 * @member {Number} invalid
 */
EmailsImportResultData.prototype.invalid = undefined;

/**
 * @member {Array.<String>} errors
 */
EmailsImportResultData.prototype.errors = undefined;

