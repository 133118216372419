const StorageKeys = Object.freeze({
    User: 'user',
    UserToken: 'user-token'
});
module.exports.StorageKeys = StorageKeys;

// User
const logIn = function(user, token, remember) {

	// Save the user
	save(StorageKeys.User, user);

	// Save the token
	let expDate;
	if (remember) {
		expDate = new Date();
		expDate.setMonth(expDate.getMonth() + 6);
	}
	saveCookie(StorageKeys.UserToken, token, expDate);

	// Go to home
	window.location = '/home';
}
module.exports.logIn = logIn;

const checkLogInStatus = function() {
	if (!get(StorageKeys.User) || !getCookie(StorageKeys.UserToken)) {
		logOut();
	}
	else if (window.location.pathname === '/') {
		window.location = '/home';
	}
}
module.exports.checkLogInStatus = checkLogInStatus;

const getUserToken = function() {
	return getCookie(StorageKeys.UserToken);
}
module.exports.getUserToken = getUserToken;

const logOut = function() {
	remove(StorageKeys.User);
	removeCookie(StorageKeys.UserToken);
	if (window.location.pathname !== '/') window.location = '/';
}
module.exports.logOut = logOut;

// Local Storage
const save = function(key, item) {
	localStorage.setItem(key, JSON.stringify(item));
}
module.exports.save = save;

const get = function(key) {
	const itemJson = localStorage.getItem(key);
	if (itemJson) return JSON.parse(itemJson);
	return;
}
module.exports.get = get;

const remove = function(key) {
	localStorage.removeItem(key);
}
module.exports.remove = remove;

const saveCookie = function(key, value, expires) {
    var expiresString = (expires) ? 'expires=' + expires.toUTCString() : '';
    document.cookie = key + '=' + value + ';' + expiresString + ';path=/';
}
module.exports.saveCookie = saveCookie;

// Cookies
const getCookie = function(key) {
    var tempName = key + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(tempName) == 0) {
            return c.substring(tempName.length, c.length);
        }
    }
    return '';
}
module.exports.getCookie = getCookie;

const removeCookie = function(key) {
    document.cookie = key + '=;expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/';
}
module.exports.removeCookie = removeCookie;