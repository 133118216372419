/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AutomationdataSettings} from './AutomationdataSettings';
import {AutomationdataSource} from './AutomationdataSource';

/**
 * The DataDataAutomationIdBody model module.
 * @module model/DataDataAutomationIdBody
 * @version 1.0.0
 */
export class DataDataAutomationIdBody {
  /**
   * Constructs a new <code>DataDataAutomationIdBody</code>.
   * @alias module:model/DataDataAutomationIdBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>DataDataAutomationIdBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DataDataAutomationIdBody} obj Optional instance to populate.
   * @return {module:model/DataDataAutomationIdBody} The populated <code>DataDataAutomationIdBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DataDataAutomationIdBody();
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('tags'))
        obj.tags = ApiClient.convertToType(data['tags'], ['String']);
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
      if (data.hasOwnProperty('query'))
        obj.query = ApiClient.convertToType(data['query'], 'String');
      if (data.hasOwnProperty('source'))
        obj.source = AutomationdataSource.constructFromObject(data['source']);
      if (data.hasOwnProperty('destinations'))
        obj.destinations = ApiClient.convertToType(data['destinations'], [AutomationdataSource]);
      if (data.hasOwnProperty('settings'))
        obj.settings = AutomationdataSettings.constructFromObject(data['settings']);
    }
    return obj;
  }
}

/**
 * @member {String} title
 */
DataDataAutomationIdBody.prototype.title = undefined;

/**
 * @member {String} description
 */
DataDataAutomationIdBody.prototype.description = undefined;

/**
 * @member {Array.<String>} tags
 */
DataDataAutomationIdBody.prototype.tags = undefined;

/**
 * @member {Boolean} enabled
 */
DataDataAutomationIdBody.prototype.enabled = undefined;

/**
 * @member {String} query
 */
DataDataAutomationIdBody.prototype.query = undefined;

/**
 * @member {module:model/AutomationdataSource} source
 */
DataDataAutomationIdBody.prototype.source = undefined;

/**
 * @member {Array.<module:model/AutomationdataSource>} destinations
 */
DataDataAutomationIdBody.prototype.destinations = undefined;

/**
 * @member {module:model/AutomationdataSettings} settings
 */
DataDataAutomationIdBody.prototype.settings = undefined;

