/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {SegmentsJoinOn} from './SegmentsJoinOn';

/**
 * The SegmentsJoin model module.
 * @module model/SegmentsJoin
 * @version 1.0.0
 */
export class SegmentsJoin {
  /**
   * Constructs a new <code>SegmentsJoin</code>.
   * @alias module:model/SegmentsJoin
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>SegmentsJoin</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SegmentsJoin} obj Optional instance to populate.
   * @return {module:model/SegmentsJoin} The populated <code>SegmentsJoin</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SegmentsJoin();
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('on'))
        obj.on = SegmentsJoinOn.constructFromObject(data['on']);
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
SegmentsJoin.TypeEnum = {
  /**
   * value: "leftOuter"
   * @const
   */
  leftOuter: "leftOuter",

  /**
   * value: "cross"
   * @const
   */
  cross: "cross"
};
/**
 * @member {module:model/SegmentsJoin.TypeEnum} type
 */
SegmentsJoin.prototype.type = undefined;

/**
 * @member {module:model/SegmentsJoinOn} on
 */
SegmentsJoin.prototype.on = undefined;

