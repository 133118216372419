/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Email} from './Email';
import {Property} from './Property';
import {QuestionAnswerAnswer} from './QuestionAnswerAnswer';

/**
 * The QuestionAnswer model module.
 * @module model/QuestionAnswer
 * @version 1.0.0
 */
export class QuestionAnswer {
  /**
   * Constructs a new <code>QuestionAnswer</code>.
   * @alias module:model/QuestionAnswer
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>QuestionAnswer</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/QuestionAnswer} obj Optional instance to populate.
   * @return {module:model/QuestionAnswer} The populated <code>QuestionAnswer</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new QuestionAnswer();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('contentQuestion'))
        obj.contentQuestion = ApiClient.convertToType(data['contentQuestion'], 'String');
      if (data.hasOwnProperty('property'))
        obj.property = Property.constructFromObject(data['property']);
      if (data.hasOwnProperty('email'))
        obj.email = Email.constructFromObject(data['email']);
      if (data.hasOwnProperty('answer'))
        obj.answer = QuestionAnswerAnswer.constructFromObject(data['answer']);
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
QuestionAnswer.prototype._id = undefined;

/**
 * @member {String} contentQuestion
 */
QuestionAnswer.prototype.contentQuestion = undefined;

/**
 * @member {module:model/Property} property
 */
QuestionAnswer.prototype.property = undefined;

/**
 * @member {module:model/Email} email
 */
QuestionAnswer.prototype.email = undefined;

/**
 * @member {module:model/QuestionAnswerAnswer} answer
 */
QuestionAnswer.prototype.answer = undefined;

/**
 * @member {String} created
 */
QuestionAnswer.prototype.created = undefined;

/**
 * @member {String} updated
 */
QuestionAnswer.prototype.updated = undefined;

