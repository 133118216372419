/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AutomationdataSourceParams} from './AutomationdataSourceParams';

/**
 * The AutomationdataSource model module.
 * @module model/AutomationdataSource
 * @version 1.0.0
 */
export class AutomationdataSource {
  /**
   * Constructs a new <code>AutomationdataSource</code>.
   * @alias module:model/AutomationdataSource
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AutomationdataSource</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AutomationdataSource} obj Optional instance to populate.
   * @return {module:model/AutomationdataSource} The populated <code>AutomationdataSource</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AutomationdataSource();
      if (data.hasOwnProperty('connection'))
        obj.connection = ApiClient.convertToType(data['connection'], 'String');
      if (data.hasOwnProperty('params'))
        obj.params = AutomationdataSourceParams.constructFromObject(data['params']);
    }
    return obj;
  }
}

/**
 * @member {String} connection
 */
AutomationdataSource.prototype.connection = undefined;

/**
 * @member {module:model/AutomationdataSourceParams} params
 */
AutomationdataSource.prototype.params = undefined;

