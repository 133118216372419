/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {NotificationAlert} from './NotificationAlert';

/**
 * The Notification model module.
 * @module model/Notification
 * @version 1.0.0
 */
export class Notification {
  /**
   * Constructs a new <code>Notification</code>.
   * @alias module:model/Notification
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>Notification</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Notification} obj Optional instance to populate.
   * @return {module:model/Notification} The populated <code>Notification</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Notification();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('alert'))
        obj.alert = NotificationAlert.constructFromObject(data['alert']);
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
Notification.prototype._id = undefined;

/**
 * @member {String} title
 */
Notification.prototype.title = undefined;

/**
 * @member {String} description
 */
Notification.prototype.description = undefined;

/**
 * @member {module:model/NotificationAlert} alert
 */
Notification.prototype.alert = undefined;

/**
 * @member {String} created
 */
Notification.prototype.created = undefined;

/**
 * @member {String} updated
 */
Notification.prototype.updated = undefined;

