/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {EmailsBody} from '../model/EmailsBody';
import {EmailsEmailIdBody} from '../model/EmailsEmailIdBody';
import {EmailsSubscribeBody} from '../model/EmailsSubscribeBody';
import {EmailsSuppressBody} from '../model/EmailsSuppressBody';
import {EmailsUnsubscribeBody} from '../model/EmailsUnsubscribeBody';
import {InlineResponse2004} from '../model/InlineResponse2004';
import {InlineResponse2005} from '../model/InlineResponse2005';
import {InlineResponse2006} from '../model/InlineResponse2006';
import {InlineResponse2007} from '../model/InlineResponse2007';
import {InlineResponse2008} from '../model/InlineResponse2008';
import {InlineResponse2013} from '../model/InlineResponse2013';
import {InlineResponse2014} from '../model/InlineResponse2014';
import {InlineResponse2015} from '../model/InlineResponse2015';

/**
* Email service.
* @module api/EmailApi
* @version 1.0.0
*/
export class EmailApi {

    /**
    * Constructs a new EmailApi. 
    * @alias module:api/EmailApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addEmail operation.
     * @callback moduleapi/EmailApi~addEmailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2015{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new email
     * @param {module:model/EmailsBody} body Email
     * @param {module:api/EmailApi~addEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addEmail(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addEmail");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2015;

      return this.apiClient.callApi(
        '/emails', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the checkEmails operation.
     * @callback moduleapi/EmailApi~checkEmailsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2014{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Check a list or CSV of emails
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.emails 
     * @param {Blob} opts.file 
     * @param {String} opts.fieldsMap 
     * @param {String} opts.settings 
     * @param {module:api/EmailApi~checkEmailsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    checkEmails(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'emails': this.apiClient.buildCollectionParam(opts['emails'], 'multi'),'file': opts['file'],'fieldsMap': opts['fieldsMap'],'settings': opts['settings']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2014;

      return this.apiClient.callApi(
        '/emails/checks', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteEmail operation.
     * @callback moduleapi/EmailApi~deleteEmailCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete an email
     * @param {String} emailId email ID
     * @param {module:api/EmailApi~deleteEmailCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteEmail(emailId, callback) {
      
      let postBody = null;
      // verify the required parameter 'emailId' is set
      if (emailId === undefined || emailId === null) {
        throw new Error("Missing the required parameter 'emailId' when calling deleteEmail");
      }

      let pathParams = {
        'emailId': emailId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/emails/{emailId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getEmail operation.
     * @callback moduleapi/EmailApi~getEmailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2015{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get an email
     * @param {String} emailId Email ID
     * @param {module:api/EmailApi~getEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getEmail(emailId, callback) {
      
      let postBody = null;
      // verify the required parameter 'emailId' is set
      if (emailId === undefined || emailId === null) {
        throw new Error("Missing the required parameter 'emailId' when calling getEmail");
      }

      let pathParams = {
        'emailId': emailId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2015;

      return this.apiClient.callApi(
        '/emails/{emailId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getEmailFields operation.
     * @callback moduleapi/EmailApi~getEmailFieldsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2005{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get email fields
     * @param {module:api/EmailApi~getEmailFieldsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getEmailFields(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2005;

      return this.apiClient.callApi(
        '/emails/imports/fields', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getEmails operation.
     * @callback moduleapi/EmailApi~getEmailsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2007{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get emails
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyword Keyword (email)
     * @param {Number} opts.limit Number of emails
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/EmailApi~getEmailsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getEmails(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'keyword': opts['keyword'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2007;

      return this.apiClient.callApi(
        '/emails', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getEmailsCheck operation.
     * @callback moduleapi/EmailApi~getEmailsCheckCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2014{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get emails check
     * @param {String} emailsCheckId Emails check id
     * @param {module:api/EmailApi~getEmailsCheckCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getEmailsCheck(emailsCheckId, callback) {
      
      let postBody = null;
      // verify the required parameter 'emailsCheckId' is set
      if (emailsCheckId === undefined || emailsCheckId === null) {
        throw new Error("Missing the required parameter 'emailsCheckId' when calling getEmailsCheck");
      }

      let pathParams = {
        'emailsCheckId': emailsCheckId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2014;

      return this.apiClient.callApi(
        '/emails/checks/{emailsCheckId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getEmailsChecks operation.
     * @callback moduleapi/EmailApi~getEmailsChecksCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2006{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get emails checks
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of emails checks
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/EmailApi~getEmailsChecksCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getEmailsChecks(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2006;

      return this.apiClient.callApi(
        '/emails/checks', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getEmailsImport operation.
     * @callback moduleapi/EmailApi~getEmailsImportCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2013{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get emails import
     * @param {String} emailsImportId Emails import id
     * @param {module:api/EmailApi~getEmailsImportCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getEmailsImport(emailsImportId, callback) {
      
      let postBody = null;
      // verify the required parameter 'emailsImportId' is set
      if (emailsImportId === undefined || emailsImportId === null) {
        throw new Error("Missing the required parameter 'emailsImportId' when calling getEmailsImport");
      }

      let pathParams = {
        'emailsImportId': emailsImportId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2013;

      return this.apiClient.callApi(
        '/emails/imports/{emailsImportId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getEmailsImports operation.
     * @callback moduleapi/EmailApi~getEmailsImportsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2004{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get emails imports
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of emails imports
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/EmailApi~getEmailsImportsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getEmailsImports(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2004;

      return this.apiClient.callApi(
        '/emails/imports', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getManagedEmailFields operation.
     * @callback moduleapi/EmailApi~getManagedEmailFieldsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2005{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get managed email fields
     * @param {module:api/EmailApi~getManagedEmailFieldsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getManagedEmailFields(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2005;

      return this.apiClient.callApi(
        '/emails/checks/fields', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the importEmails operation.
     * @callback moduleapi/EmailApi~importEmailsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2013{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Import a list or CSV of emails
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.emails 
     * @param {Blob} opts.file 
     * @param {String} opts.fieldsMap 
     * @param {module:api/EmailApi~importEmailsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    importEmails(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'emails': this.apiClient.buildCollectionParam(opts['emails'], 'multi'),'file': opts['file'],'fieldsMap': opts['fieldsMap']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2013;

      return this.apiClient.callApi(
        '/emails/imports', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the subscribeEmail operation.
     * @callback moduleapi/EmailApi~subscribeEmailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2008{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Subscribe to a property
     * @param {module:model/EmailsSubscribeBody} body Email
     * @param {module:api/EmailApi~subscribeEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    subscribeEmail(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling subscribeEmail");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2008;

      return this.apiClient.callApi(
        '/emails/subscribe', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the suppressEmail operation.
     * @callback moduleapi/EmailApi~suppressEmailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2008{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Suppress an email
     * @param {module:model/EmailsSuppressBody} body Email
     * @param {module:api/EmailApi~suppressEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    suppressEmail(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling suppressEmail");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2008;

      return this.apiClient.callApi(
        '/emails/suppress', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the unsubscribeEmail operation.
     * @callback moduleapi/EmailApi~unsubscribeEmailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2008{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Unsubscribe from a property
     * @param {module:model/EmailsUnsubscribeBody} body Email
     * @param {module:api/EmailApi~unsubscribeEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    unsubscribeEmail(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling unsubscribeEmail");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2008;

      return this.apiClient.callApi(
        '/emails/unsubscribe', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateEmail operation.
     * @callback moduleapi/EmailApi~updateEmailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2015{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update an email
     * @param {module:model/EmailsEmailIdBody} body Email
     * @param {String} emailId Email ID
     * @param {module:api/EmailApi~updateEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateEmail(body, emailId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateEmail");
      }
      // verify the required parameter 'emailId' is set
      if (emailId === undefined || emailId === null) {
        throw new Error("Missing the required parameter 'emailId' when calling updateEmail");
      }

      let pathParams = {
        'emailId': emailId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2015;

      return this.apiClient.callApi(
        '/emails/{emailId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}