/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EmailsCheckResultData model module.
 * @module model/EmailsCheckResultData
 * @version 1.0.0
 */
export class EmailsCheckResultData {
  /**
   * Constructs a new <code>EmailsCheckResultData</code>.
   * @alias module:model/EmailsCheckResultData
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailsCheckResultData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailsCheckResultData} obj Optional instance to populate.
   * @return {module:model/EmailsCheckResultData} The populated <code>EmailsCheckResultData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailsCheckResultData();
      if (data.hasOwnProperty('total'))
        obj.total = ApiClient.convertToType(data['total'], 'Number');
      if (data.hasOwnProperty('active_old'))
        obj.active_old = ApiClient.convertToType(data['active_old'], 'Number');
      if (data.hasOwnProperty('active_new'))
        obj.active_new = ApiClient.convertToType(data['active_new'], 'Number');
      if (data.hasOwnProperty('inactive_old'))
        obj.inactive_old = ApiClient.convertToType(data['inactive_old'], 'Number');
      if (data.hasOwnProperty('inactive_new'))
        obj.inactive_new = ApiClient.convertToType(data['inactive_new'], 'Number');
      if (data.hasOwnProperty('verified_old'))
        obj.verified_old = ApiClient.convertToType(data['verified_old'], 'Number');
      if (data.hasOwnProperty('verified_new'))
        obj.verified_new = ApiClient.convertToType(data['verified_new'], 'Number');
      if (data.hasOwnProperty('unverified_old'))
        obj.unverified_old = ApiClient.convertToType(data['unverified_old'], 'Number');
      if (data.hasOwnProperty('unverified_new'))
        obj.unverified_new = ApiClient.convertToType(data['unverified_new'], 'Number');
      if (data.hasOwnProperty('total_active_verified'))
        obj.total_active_verified = ApiClient.convertToType(data['total_active_verified'], 'Number');
      if (data.hasOwnProperty('errors'))
        obj.errors = ApiClient.convertToType(data['errors'], ['String']);
    }
    return obj;
  }
}

/**
 * @member {Number} total
 */
EmailsCheckResultData.prototype.total = undefined;

/**
 * @member {Number} active_old
 */
EmailsCheckResultData.prototype.active_old = undefined;

/**
 * @member {Number} active_new
 */
EmailsCheckResultData.prototype.active_new = undefined;

/**
 * @member {Number} inactive_old
 */
EmailsCheckResultData.prototype.inactive_old = undefined;

/**
 * @member {Number} inactive_new
 */
EmailsCheckResultData.prototype.inactive_new = undefined;

/**
 * @member {Number} verified_old
 */
EmailsCheckResultData.prototype.verified_old = undefined;

/**
 * @member {Number} verified_new
 */
EmailsCheckResultData.prototype.verified_new = undefined;

/**
 * @member {Number} unverified_old
 */
EmailsCheckResultData.prototype.unverified_old = undefined;

/**
 * @member {Number} unverified_new
 */
EmailsCheckResultData.prototype.unverified_new = undefined;

/**
 * @member {Number} total_active_verified
 */
EmailsCheckResultData.prototype.total_active_verified = undefined;

/**
 * @member {Array.<String>} errors
 */
EmailsCheckResultData.prototype.errors = undefined;

