/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {EmailProfile} from './EmailProfile';
import {EmailProvider} from './EmailProvider';
import {EmailVerification} from './EmailVerification';

/**
 * The EmailsBody model module.
 * @module model/EmailsBody
 * @version 1.0.0
 */
export class EmailsBody {
  /**
   * Constructs a new <code>EmailsBody</code>.
   * @alias module:model/EmailsBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailsBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailsBody} obj Optional instance to populate.
   * @return {module:model/EmailsBody} The populated <code>EmailsBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailsBody();
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('profile'))
        obj.profile = EmailProfile.constructFromObject(data['profile']);
      if (data.hasOwnProperty('provider'))
        obj.provider = EmailProvider.constructFromObject(data['provider']);
      if (data.hasOwnProperty('verification'))
        obj.verification = EmailVerification.constructFromObject(data['verification']);
    }
    return obj;
  }
}

/**
 * @member {String} email
 */
EmailsBody.prototype.email = undefined;

/**
 * @member {module:model/EmailProfile} profile
 */
EmailsBody.prototype.profile = undefined;

/**
 * @member {module:model/EmailProvider} provider
 */
EmailsBody.prototype.provider = undefined;

/**
 * @member {module:model/EmailVerification} verification
 */
EmailsBody.prototype.verification = undefined;

