/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EmailAct model module.
 * @module model/EmailAct
 * @version 1.0.0
 */
export class EmailAct {
  /**
   * Constructs a new <code>EmailAct</code>.
   * @alias module:model/EmailAct
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailAct</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailAct} obj Optional instance to populate.
   * @return {module:model/EmailAct} The populated <code>EmailAct</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailAct();
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('date'))
        obj.date = ApiClient.convertToType(data['date'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} type
 */
EmailAct.prototype.type = undefined;

/**
 * @member {String} description
 */
EmailAct.prototype.description = undefined;

/**
 * @member {String} date
 */
EmailAct.prototype.date = undefined;

