/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {EventsTriggerAutomationEventIdBody} from '../model/EventsTriggerAutomationEventIdBody';
import {InlineResponse20019} from '../model/InlineResponse20019';
import {InlineResponse20112} from '../model/InlineResponse20112';
import {TriggersEventsBody} from '../model/TriggersEventsBody';

/**
* TriggerAutomationEvent service.
* @module api/TriggerAutomationEventApi
* @version 1.0.0
*/
export class TriggerAutomationEventApi {

    /**
    * Constructs a new TriggerAutomationEventApi. 
    * @alias module:api/TriggerAutomationEventApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addTriggerAutomationEvent operation.
     * @callback moduleapi/TriggerAutomationEventApi~addTriggerAutomationEventCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20112{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new trigger automation event
     * @param {module:model/TriggersEventsBody} body Trigger Automation Event
     * @param {module:api/TriggerAutomationEventApi~addTriggerAutomationEventCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addTriggerAutomationEvent(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addTriggerAutomationEvent");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20112;

      return this.apiClient.callApi(
        '/automation/triggers/events', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteTriggerAutomationEvent operation.
     * @callback moduleapi/TriggerAutomationEventApi~deleteTriggerAutomationEventCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a trigger automation event
     * @param {String} triggerAutomationEventId Trigger Automation Event ID
     * @param {module:api/TriggerAutomationEventApi~deleteTriggerAutomationEventCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteTriggerAutomationEvent(triggerAutomationEventId, callback) {
      
      let postBody = null;
      // verify the required parameter 'triggerAutomationEventId' is set
      if (triggerAutomationEventId === undefined || triggerAutomationEventId === null) {
        throw new Error("Missing the required parameter 'triggerAutomationEventId' when calling deleteTriggerAutomationEvent");
      }

      let pathParams = {
        'triggerAutomationEventId': triggerAutomationEventId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/automation/triggers/events/{triggerAutomationEventId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getTriggerAutomationEvent operation.
     * @callback moduleapi/TriggerAutomationEventApi~getTriggerAutomationEventCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20112{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a trigger automation event
     * @param {String} triggerAutomationEventId Trigger Automation Event ID
     * @param {module:api/TriggerAutomationEventApi~getTriggerAutomationEventCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getTriggerAutomationEvent(triggerAutomationEventId, callback) {
      
      let postBody = null;
      // verify the required parameter 'triggerAutomationEventId' is set
      if (triggerAutomationEventId === undefined || triggerAutomationEventId === null) {
        throw new Error("Missing the required parameter 'triggerAutomationEventId' when calling getTriggerAutomationEvent");
      }

      let pathParams = {
        'triggerAutomationEventId': triggerAutomationEventId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20112;

      return this.apiClient.callApi(
        '/automation/triggers/events/{triggerAutomationEventId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getTriggerAutomationEvents operation.
     * @callback moduleapi/TriggerAutomationEventApi~getTriggerAutomationEventsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20019{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get trigger automation events
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.type Type
     * @param {String} opts.keyword Keyword search in title and description
     * @param {Number} opts.limit Number of trigger automation events
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/TriggerAutomationEventApi~getTriggerAutomationEventsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getTriggerAutomationEvents(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'type': opts['type'],'keyword': opts['keyword'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20019;

      return this.apiClient.callApi(
        '/automation/triggers/events', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateTriggerAutomationEvent operation.
     * @callback moduleapi/TriggerAutomationEventApi~updateTriggerAutomationEventCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20112{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a trigger automation event
     * @param {module:model/EventsTriggerAutomationEventIdBody} body Trigger Automation Event
     * @param {String} triggerAutomationEventId Trigger Automation Event ID
     * @param {module:api/TriggerAutomationEventApi~updateTriggerAutomationEventCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateTriggerAutomationEvent(body, triggerAutomationEventId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateTriggerAutomationEvent");
      }
      // verify the required parameter 'triggerAutomationEventId' is set
      if (triggerAutomationEventId === undefined || triggerAutomationEventId === null) {
        throw new Error("Missing the required parameter 'triggerAutomationEventId' when calling updateTriggerAutomationEvent");
      }

      let pathParams = {
        'triggerAutomationEventId': triggerAutomationEventId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20112;

      return this.apiClient.callApi(
        '/automation/triggers/events/{triggerAutomationEventId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}