/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {ConnectionsDataAutomationConnectionIdBody} from '../model/ConnectionsDataAutomationConnectionIdBody';
import {DataConnectionsBody} from '../model/DataConnectionsBody';
import {InlineResponse20022} from '../model/InlineResponse20022';
import {InlineResponse20115} from '../model/InlineResponse20115';

/**
* DataAutomationConnection service.
* @module api/DataAutomationConnectionApi
* @version 1.0.0
*/
export class DataAutomationConnectionApi {

    /**
    * Constructs a new DataAutomationConnectionApi. 
    * @alias module:api/DataAutomationConnectionApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addDataAutomationConnection operation.
     * @callback moduleapi/DataAutomationConnectionApi~addDataAutomationConnectionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20115{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new data automation connection
     * @param {module:model/DataConnectionsBody} body Data Automation Connection
     * @param {module:api/DataAutomationConnectionApi~addDataAutomationConnectionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addDataAutomationConnection(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addDataAutomationConnection");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20115;

      return this.apiClient.callApi(
        '/automation/data/connections', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteDataAutomationConnection operation.
     * @callback moduleapi/DataAutomationConnectionApi~deleteDataAutomationConnectionCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a data automation connection
     * @param {String} dataAutomationConnectionId Data Automation Connection ID
     * @param {module:api/DataAutomationConnectionApi~deleteDataAutomationConnectionCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteDataAutomationConnection(dataAutomationConnectionId, callback) {
      
      let postBody = null;
      // verify the required parameter 'dataAutomationConnectionId' is set
      if (dataAutomationConnectionId === undefined || dataAutomationConnectionId === null) {
        throw new Error("Missing the required parameter 'dataAutomationConnectionId' when calling deleteDataAutomationConnection");
      }

      let pathParams = {
        'dataAutomationConnectionId': dataAutomationConnectionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/automation/data/connections/{dataAutomationConnectionId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getDataAutomationConnection operation.
     * @callback moduleapi/DataAutomationConnectionApi~getDataAutomationConnectionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20115{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a data automation connection
     * @param {String} dataAutomationConnectionId Data Automation Connection ID
     * @param {module:api/DataAutomationConnectionApi~getDataAutomationConnectionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getDataAutomationConnection(dataAutomationConnectionId, callback) {
      
      let postBody = null;
      // verify the required parameter 'dataAutomationConnectionId' is set
      if (dataAutomationConnectionId === undefined || dataAutomationConnectionId === null) {
        throw new Error("Missing the required parameter 'dataAutomationConnectionId' when calling getDataAutomationConnection");
      }

      let pathParams = {
        'dataAutomationConnectionId': dataAutomationConnectionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20115;

      return this.apiClient.callApi(
        '/automation/data/connections/{dataAutomationConnectionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getDataAutomationConnections operation.
     * @callback moduleapi/DataAutomationConnectionApi~getDataAutomationConnectionsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20022{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get data automation conections
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.type Type
     * @param {String} opts.keyword Keyword search in title and description
     * @param {Number} opts.limit Number of data automation connections
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/DataAutomationConnectionApi~getDataAutomationConnectionsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getDataAutomationConnections(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'type': opts['type'],'keyword': opts['keyword'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20022;

      return this.apiClient.callApi(
        '/automation/data/connections', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateDataAutomationConnection operation.
     * @callback moduleapi/DataAutomationConnectionApi~updateDataAutomationConnectionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20115{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a data automation connection
     * @param {module:model/ConnectionsDataAutomationConnectionIdBody} body Data Automation Connection
     * @param {String} dataAutomationConnectionId Data Automation Connection ID
     * @param {module:api/DataAutomationConnectionApi~updateDataAutomationConnectionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateDataAutomationConnection(body, dataAutomationConnectionId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateDataAutomationConnection");
      }
      // verify the required parameter 'dataAutomationConnectionId' is set
      if (dataAutomationConnectionId === undefined || dataAutomationConnectionId === null) {
        throw new Error("Missing the required parameter 'dataAutomationConnectionId' when calling updateDataAutomationConnection");
      }

      let pathParams = {
        'dataAutomationConnectionId': dataAutomationConnectionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20115;

      return this.apiClient.callApi(
        '/automation/data/connections/{dataAutomationConnectionId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}