/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {InlineResponse20010} from '../model/InlineResponse20010';
import {InlineResponse2017} from '../model/InlineResponse2017';
import {PropertiesBody} from '../model/PropertiesBody';
import {PropertiesPropertyIdBody} from '../model/PropertiesPropertyIdBody';

/**
* Property service.
* @module api/PropertyApi
* @version 1.0.0
*/
export class PropertyApi {

    /**
    * Constructs a new PropertyApi. 
    * @alias module:api/PropertyApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addProperty operation.
     * @callback moduleapi/PropertyApi~addPropertyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2017{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new property
     * @param {module:model/PropertiesBody} body Property
     * @param {module:api/PropertyApi~addPropertyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addProperty(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addProperty");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2017;

      return this.apiClient.callApi(
        '/properties', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteProperty operation.
     * @callback moduleapi/PropertyApi~deletePropertyCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a property
     * @param {String} propertyId Property ID
     * @param {module:api/PropertyApi~deletePropertyCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteProperty(propertyId, callback) {
      
      let postBody = null;
      // verify the required parameter 'propertyId' is set
      if (propertyId === undefined || propertyId === null) {
        throw new Error("Missing the required parameter 'propertyId' when calling deleteProperty");
      }

      let pathParams = {
        'propertyId': propertyId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/properties/{propertyId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getProperties operation.
     * @callback moduleapi/PropertyApi~getPropertiesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20010{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get properties
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyword Keyword to search title and description
     * @param {String} opts.domain Domain to filter for
     * @param {Boolean} opts.enabled Enabled
     * @param {Number} opts.limit Number of properties
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/PropertyApi~getPropertiesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getProperties(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'keyword': opts['keyword'],'domain': opts['domain'],'enabled': opts['enabled'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20010;

      return this.apiClient.callApi(
        '/properties', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getProperty operation.
     * @callback moduleapi/PropertyApi~getPropertyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2017{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a property
     * @param {String} propertyId Property ID
     * @param {module:api/PropertyApi~getPropertyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getProperty(propertyId, callback) {
      
      let postBody = null;
      // verify the required parameter 'propertyId' is set
      if (propertyId === undefined || propertyId === null) {
        throw new Error("Missing the required parameter 'propertyId' when calling getProperty");
      }

      let pathParams = {
        'propertyId': propertyId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2017;

      return this.apiClient.callApi(
        '/properties/{propertyId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateProperty operation.
     * @callback moduleapi/PropertyApi~updatePropertyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2017{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a property
     * @param {module:model/PropertiesPropertyIdBody} body Property
     * @param {String} propertyId Property ID
     * @param {module:api/PropertyApi~updatePropertyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateProperty(body, propertyId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateProperty");
      }
      // verify the required parameter 'propertyId' is set
      if (propertyId === undefined || propertyId === null) {
        throw new Error("Missing the required parameter 'propertyId' when calling updateProperty");
      }

      let pathParams = {
        'propertyId': propertyId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2017;

      return this.apiClient.callApi(
        '/properties/{propertyId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}