/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {EmailAct} from './EmailAct';
import {EmailProfile} from './EmailProfile';
import {EmailProvider} from './EmailProvider';
import {EmailVerification} from './EmailVerification';

/**
 * The Email model module.
 * @module model/Email
 * @version 1.0.0
 */
export class Email {
  /**
   * Constructs a new <code>Email</code>.
   * @alias module:model/Email
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>Email</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Email} obj Optional instance to populate.
   * @return {module:model/Email} The populated <code>Email</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Email();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('profile'))
        obj.profile = EmailProfile.constructFromObject(data['profile']);
      if (data.hasOwnProperty('provider'))
        obj.provider = EmailProvider.constructFromObject(data['provider']);
      if (data.hasOwnProperty('verification'))
        obj.verification = EmailVerification.constructFromObject(data['verification']);
      if (data.hasOwnProperty('activity'))
        obj.activity = EmailAct.constructFromObject(data['activity']);
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
Email.prototype._id = undefined;

/**
 * @member {String} email
 */
Email.prototype.email = undefined;

/**
 * @member {module:model/EmailProfile} profile
 */
Email.prototype.profile = undefined;

/**
 * @member {module:model/EmailProvider} provider
 */
Email.prototype.provider = undefined;

/**
 * @member {module:model/EmailVerification} verification
 */
Email.prototype.verification = undefined;

/**
 * @member {module:model/EmailAct} activity
 */
Email.prototype.activity = undefined;

/**
 * @member {String} created
 */
Email.prototype.created = undefined;

/**
 * @member {String} updated
 */
Email.prototype.updated = undefined;

