/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The UsersBody model module.
 * @module model/UsersBody
 * @version 1.0.0
 */
export class UsersBody {
  /**
   * Constructs a new <code>UsersBody</code>.
   * @alias module:model/UsersBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>UsersBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UsersBody} obj Optional instance to populate.
   * @return {module:model/UsersBody} The populated <code>UsersBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UsersBody();
      if (data.hasOwnProperty('username'))
        obj.username = ApiClient.convertToType(data['username'], 'String');
      if (data.hasOwnProperty('password'))
        obj.password = ApiClient.convertToType(data['password'], 'String');
      if (data.hasOwnProperty('firstName'))
        obj.firstName = ApiClient.convertToType(data['firstName'], 'String');
      if (data.hasOwnProperty('lastName'))
        obj.lastName = ApiClient.convertToType(data['lastName'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} username
 */
UsersBody.prototype.username = undefined;

/**
 * @member {String} password
 */
UsersBody.prototype.password = undefined;

/**
 * @member {String} firstName
 */
UsersBody.prototype.firstName = undefined;

/**
 * @member {String} lastName
 */
UsersBody.prototype.lastName = undefined;

