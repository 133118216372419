/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {InlineResponse20012} from '../model/InlineResponse20012';
import {InlineResponse20013} from '../model/InlineResponse20013';
import {InlineResponse2019} from '../model/InlineResponse2019';
import {SegmentsBody} from '../model/SegmentsBody';
import {SegmentsSegmentIdBody} from '../model/SegmentsSegmentIdBody';

/**
* Segment service.
* @module api/SegmentApi
* @version 1.0.0
*/
export class SegmentApi {

    /**
    * Constructs a new SegmentApi. 
    * @alias module:api/SegmentApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addSegment operation.
     * @callback moduleapi/SegmentApi~addSegmentCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2019{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new segment
     * @param {module:model/SegmentsBody} body Segment
     * @param {module:api/SegmentApi~addSegmentCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addSegment(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addSegment");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2019;

      return this.apiClient.callApi(
        '/segments', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteSegment operation.
     * @callback moduleapi/SegmentApi~deleteSegmentCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a segment
     * @param {String} segmentId Segment ID
     * @param {module:api/SegmentApi~deleteSegmentCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteSegment(segmentId, callback) {
      
      let postBody = null;
      // verify the required parameter 'segmentId' is set
      if (segmentId === undefined || segmentId === null) {
        throw new Error("Missing the required parameter 'segmentId' when calling deleteSegment");
      }

      let pathParams = {
        'segmentId': segmentId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/segments/{segmentId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getSegment operation.
     * @callback moduleapi/SegmentApi~getSegmentCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2019{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a segment
     * @param {String} segmentId Segment ID
     * @param {module:api/SegmentApi~getSegmentCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getSegment(segmentId, callback) {
      
      let postBody = null;
      // verify the required parameter 'segmentId' is set
      if (segmentId === undefined || segmentId === null) {
        throw new Error("Missing the required parameter 'segmentId' when calling getSegment");
      }

      let pathParams = {
        'segmentId': segmentId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2019;

      return this.apiClient.callApi(
        '/segments/{segmentId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getSegmentSize operation.
     * @callback moduleapi/SegmentApi~getSegmentSizeCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20013{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get segment size
     * @param {String} segmentId Segment id
     * @param {module:api/SegmentApi~getSegmentSizeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getSegmentSize(segmentId, callback) {
      
      let postBody = null;
      // verify the required parameter 'segmentId' is set
      if (segmentId === undefined || segmentId === null) {
        throw new Error("Missing the required parameter 'segmentId' when calling getSegmentSize");
      }

      let pathParams = {
        'segmentId': segmentId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20013;

      return this.apiClient.callApi(
        '/segments/{segmentId}/size', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getSegments operation.
     * @callback moduleapi/SegmentApi~getSegmentsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20012{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get segments
     * @param {Object} opts Optional parameters
     * @param {String} opts.propertyId Property id
     * @param {String} opts.keyword Keyword search in title and description
     * @param {module:model/String} opts.type Type of segment
     * @param {module:model/String} opts.globalType Global type of segment
     * @param {Boolean} opts.enabled Enabled
     * @param {Number} opts.limit Number of segments
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/SegmentApi~getSegmentsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getSegments(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'propertyId': opts['propertyId'],'keyword': opts['keyword'],'type': opts['type'],'globalType': opts['globalType'],'enabled': opts['enabled'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20012;

      return this.apiClient.callApi(
        '/segments', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateSegment operation.
     * @callback moduleapi/SegmentApi~updateSegmentCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2019{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a segment
     * @param {module:model/SegmentsSegmentIdBody} body Segment
     * @param {String} segmentId Segment ID
     * @param {module:api/SegmentApi~updateSegmentCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateSegment(body, segmentId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateSegment");
      }
      // verify the required parameter 'segmentId' is set
      if (segmentId === undefined || segmentId === null) {
        throw new Error("Missing the required parameter 'segmentId' when calling updateSegment");
      }

      let pathParams = {
        'segmentId': segmentId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2019;

      return this.apiClient.callApi(
        '/segments/{segmentId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}