/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Property} from './Property';
import {SegmentsJoin} from './SegmentsJoin';

/**
 * The Segment model module.
 * @module model/Segment
 * @version 1.0.0
 */
export class Segment {
  /**
   * Constructs a new <code>Segment</code>.
   * @alias module:model/Segment
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>Segment</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Segment} obj Optional instance to populate.
   * @return {module:model/Segment} The populated <code>Segment</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Segment();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('property'))
        obj.property = Property.constructFromObject(data['property']);
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('globalType'))
        obj.globalType = ApiClient.convertToType(data['globalType'], 'String');
      if (data.hasOwnProperty('query'))
        obj.query = ApiClient.convertToType(data['query'], 'String');
      if (data.hasOwnProperty('join'))
        obj.join = SegmentsJoin.constructFromObject(data['join']);
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
Segment.prototype._id = undefined;

/**
 * @member {module:model/Property} property
 */
Segment.prototype.property = undefined;

/**
 * @member {String} title
 */
Segment.prototype.title = undefined;

/**
 * @member {String} description
 */
Segment.prototype.description = undefined;

/**
 * @member {Boolean} enabled
 */
Segment.prototype.enabled = undefined;

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
Segment.TypeEnum = {
  /**
   * value: "email"
   * @const
   */
  email: "email",

  /**
   * value: "suppression"
   * @const
   */
  suppression: "suppression",

  /**
   * value: "data"
   * @const
   */
  data: "data"
};
/**
 * @member {module:model/Segment.TypeEnum} type
 */
Segment.prototype.type = undefined;

/**
 * Allowed values for the <code>globalType</code> property.
 * @enum {String}
 * @readonly
 */
Segment.GlobalTypeEnum = {
  /**
   * value: "none"
   * @const
   */
  none: "none",

  /**
   * value: "property"
   * @const
   */
  property: "property",

  /**
   * value: "global"
   * @const
   */
  global: "global"
};
/**
 * @member {module:model/Segment.GlobalTypeEnum} globalType
 */
Segment.prototype.globalType = undefined;

/**
 * @member {String} query
 */
Segment.prototype.query = undefined;

/**
 * @member {module:model/SegmentsJoin} join
 */
Segment.prototype.join = undefined;

/**
 * @member {String} created
 */
Segment.prototype.created = undefined;

/**
 * @member {String} updated
 */
Segment.prototype.updated = undefined;

