/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {ActivitiesEmailActivityIdBody} from '../model/ActivitiesEmailActivityIdBody';
import {EmailIdActivitiesBody} from '../model/EmailIdActivitiesBody';
import {InlineResponse2009} from '../model/InlineResponse2009';
import {InlineResponse2016} from '../model/InlineResponse2016';

/**
* EmailActivity service.
* @module api/EmailActivityApi
* @version 1.0.0
*/
export class EmailActivityApi {

    /**
    * Constructs a new EmailActivityApi. 
    * @alias module:api/EmailActivityApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addEmailActivity operation.
     * @callback moduleapi/EmailActivityApi~addEmailActivityCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2016{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new email activity
     * @param {module:model/EmailIdActivitiesBody} body EmailActivity
     * @param {String} emailId Email Id
     * @param {module:api/EmailActivityApi~addEmailActivityCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addEmailActivity(body, emailId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addEmailActivity");
      }
      // verify the required parameter 'emailId' is set
      if (emailId === undefined || emailId === null) {
        throw new Error("Missing the required parameter 'emailId' when calling addEmailActivity");
      }

      let pathParams = {
        'emailId': emailId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2016;

      return this.apiClient.callApi(
        '/emails/{emailId}/activities', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteEmailActivity operation.
     * @callback moduleapi/EmailActivityApi~deleteEmailActivityCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete an email activity
     * @param {String} emailId Email ID
     * @param {String} emailActivityId Email Activity ID
     * @param {module:api/EmailActivityApi~deleteEmailActivityCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteEmailActivity(emailId, emailActivityId, callback) {
      
      let postBody = null;
      // verify the required parameter 'emailId' is set
      if (emailId === undefined || emailId === null) {
        throw new Error("Missing the required parameter 'emailId' when calling deleteEmailActivity");
      }
      // verify the required parameter 'emailActivityId' is set
      if (emailActivityId === undefined || emailActivityId === null) {
        throw new Error("Missing the required parameter 'emailActivityId' when calling deleteEmailActivity");
      }

      let pathParams = {
        'emailId': emailId,'emailActivityId': emailActivityId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/emails/{emailId}/activities/{emailActivityId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getEmailActivities operation.
     * @callback moduleapi/EmailActivityApi~getEmailActivitiesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2009{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get email activities for an email ID
     * @param {String} emailId Email ID
     * @param {Object} opts Optional parameters
     * @param {String} opts.propertyId Property ID
     * @param {module:model/String} opts.state State
     * @param {Number} opts.limit Number of email activities
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/EmailActivityApi~getEmailActivitiesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getEmailActivities(emailId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'emailId' is set
      if (emailId === undefined || emailId === null) {
        throw new Error("Missing the required parameter 'emailId' when calling getEmailActivities");
      }

      let pathParams = {
        'emailId': emailId
      };
      let queryParams = {
        'propertyId': opts['propertyId'],'state': opts['state'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2009;

      return this.apiClient.callApi(
        '/emails/{emailId}/activities', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getEmailActivity operation.
     * @callback moduleapi/EmailActivityApi~getEmailActivityCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2016{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get an email activity
     * @param {String} emailId Email ID
     * @param {String} emailActivityId Email Activity ID
     * @param {module:api/EmailActivityApi~getEmailActivityCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getEmailActivity(emailId, emailActivityId, callback) {
      
      let postBody = null;
      // verify the required parameter 'emailId' is set
      if (emailId === undefined || emailId === null) {
        throw new Error("Missing the required parameter 'emailId' when calling getEmailActivity");
      }
      // verify the required parameter 'emailActivityId' is set
      if (emailActivityId === undefined || emailActivityId === null) {
        throw new Error("Missing the required parameter 'emailActivityId' when calling getEmailActivity");
      }

      let pathParams = {
        'emailId': emailId,'emailActivityId': emailActivityId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2016;

      return this.apiClient.callApi(
        '/emails/{emailId}/activities/{emailActivityId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateEmailActivity operation.
     * @callback moduleapi/EmailActivityApi~updateEmailActivityCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2016{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update an email activity
     * @param {module:model/ActivitiesEmailActivityIdBody} body Email Activity
     * @param {String} emailId Email ID
     * @param {String} emailActivityId Email Activity ID
     * @param {module:api/EmailActivityApi~updateEmailActivityCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateEmailActivity(body, emailId, emailActivityId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateEmailActivity");
      }
      // verify the required parameter 'emailId' is set
      if (emailId === undefined || emailId === null) {
        throw new Error("Missing the required parameter 'emailId' when calling updateEmailActivity");
      }
      // verify the required parameter 'emailActivityId' is set
      if (emailActivityId === undefined || emailActivityId === null) {
        throw new Error("Missing the required parameter 'emailActivityId' when calling updateEmailActivity");
      }

      let pathParams = {
        'emailId': emailId,'emailActivityId': emailActivityId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2016;

      return this.apiClient.callApi(
        '/emails/{emailId}/activities/{emailActivityId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}