/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {DataAutomation} from './DataAutomation';
import {DataAutomationRunResult} from './DataAutomationRunResult';

/**
 * The DataAutomationRun model module.
 * @module model/DataAutomationRun
 * @version 1.0.0
 */
export class DataAutomationRun {
  /**
   * Constructs a new <code>DataAutomationRun</code>.
   * @alias module:model/DataAutomationRun
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>DataAutomationRun</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DataAutomationRun} obj Optional instance to populate.
   * @return {module:model/DataAutomationRun} The populated <code>DataAutomationRun</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DataAutomationRun();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('dataAutomation'))
        obj.dataAutomation = DataAutomation.constructFromObject(data['dataAutomation']);
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'String');
      if (data.hasOwnProperty('startDate'))
        obj.startDate = ApiClient.convertToType(data['startDate'], 'String');
      if (data.hasOwnProperty('endDate'))
        obj.endDate = ApiClient.convertToType(data['endDate'], 'String');
      if (data.hasOwnProperty('result'))
        obj.result = DataAutomationRunResult.constructFromObject(data['result']);
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
DataAutomationRun.prototype._id = undefined;

/**
 * @member {module:model/DataAutomation} dataAutomation
 */
DataAutomationRun.prototype.dataAutomation = undefined;

/**
 * Allowed values for the <code>state</code> property.
 * @enum {String}
 * @readonly
 */
DataAutomationRun.StateEnum = {
  /**
   * value: "started"
   * @const
   */
  started: "started",

  /**
   * value: "running"
   * @const
   */
  running: "running",

  /**
   * value: "done"
   * @const
   */
  done: "done"
};
/**
 * @member {module:model/DataAutomationRun.StateEnum} state
 */
DataAutomationRun.prototype.state = undefined;

/**
 * @member {String} startDate
 */
DataAutomationRun.prototype.startDate = undefined;

/**
 * @member {String} endDate
 */
DataAutomationRun.prototype.endDate = undefined;

/**
 * @member {module:model/DataAutomationRunResult} result
 */
DataAutomationRun.prototype.result = undefined;

/**
 * @member {String} created
 */
DataAutomationRun.prototype.created = undefined;

/**
 * @member {String} updated
 */
DataAutomationRun.prototype.updated = undefined;

