/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EmailVerification model module.
 * @module model/EmailVerification
 * @version 1.0.0
 */
export class EmailVerification {
  /**
   * Constructs a new <code>EmailVerification</code>.
   * @alias module:model/EmailVerification
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailVerification</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailVerification} obj Optional instance to populate.
   * @return {module:model/EmailVerification} The populated <code>EmailVerification</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailVerification();
      if (data.hasOwnProperty('provider'))
        obj.provider = ApiClient.convertToType(data['provider'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = ApiClient.convertToType(data['status'], 'String');
      if (data.hasOwnProperty('verified'))
        obj.verified = ApiClient.convertToType(data['verified'], 'Boolean');
      if (data.hasOwnProperty('date'))
        obj.date = ApiClient.convertToType(data['date'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} provider
 */
EmailVerification.prototype.provider = undefined;

/**
 * @member {String} status
 */
EmailVerification.prototype.status = undefined;

/**
 * @member {Boolean} verified
 */
EmailVerification.prototype.verified = undefined;

/**
 * @member {String} date
 */
EmailVerification.prototype.date = undefined;

