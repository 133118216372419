// Load the packages
const React = require('react');
const ReactDOM = require('react-dom');
const config = require('../../../../../config');
const Api = require('../../../api/index');
const { checkLogInStatus, logIn } = require('../storage/storage');

class Intro extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: undefined,
			password: undefined,
			rememberMe: true
		}
	}

	componentDidMount() {
		checkLogInStatus();

		// Setup api client
		const apiClient = new Api.ApiClient();
		apiClient.basePath = config.api.url.concat(apiClient.basePath);
		this.api_user = new Api.UserApi(apiClient);
	}

	usernameInputChanged = (event) => {
		this.setState({ username: event.currentTarget.value });
	}

	passwordInputChanged = (event) => {
		this.setState({ password: event.currentTarget.value });
	}

	rememberMeCheckboxValueChanged = (event) => {
		this.setState({ rememberMe: event.currentTarget.checked });
	}

	loginButtonClicked = (event) => {
		event.preventDefault();
      	event.stopPropagation();

      	this.api_user.loginUser({
			username: this.state.username,
			password: this.state.password
		}, (error, data, response) => {
			// if (response.statusCode === 401) logOut();	// Not necessary here
			if (error) return alert('Error: ' + error);

			// Log in
			logIn(data.data, data.token, this.state.rememberMe);
		});
	}

	render() {	
		return (
			<div className="splash-container">
		        <div className="card ">
		            <div className="card-header text-center">Marketing</div>
		            <div className="card-body">
		                <form>
		                    <div className="form-group">
		                        <input className="form-control form-control-lg" id="username" type="text" placeholder="Username" autoComplete="off" onChange={this.usernameInputChanged} />
		                    </div>
		                    <div className="form-group">
		                        <input className="form-control form-control-lg" id="password" type="password" placeholder="Password" onChange={this.passwordInputChanged} />
		                    </div>
		                    <div className="form-group">
		                        <label className="custom-control custom-checkbox">
		                            <input className="custom-control-input" type="checkbox" onChange={this.rememberMeCheckboxValueChanged} checked={true} /><span className="custom-control-label">Remember Me</span>
		                        </label>
		                    </div>
		                    <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={this.loginButtonClicked}>Sign in</button>
		                </form>
		            </div>
		        </div>
		    </div>
		);
	}
}

// Server
if (typeof window === 'undefined') module.exports = Intro;
// Browser
else ReactDOM.hydrate(<Intro />, document.getElementById('react'));
