const config = {};

// Global
config.global = {
	timezone: 'America/New_York',
	env: process.env.ENV || 'dev'					// dev | prod
}

// Server
config.server = {
	port: 8000,
	concurrency: process.env.WEB_CONCURRENCY || 1,
	root_dir_path: __dirname
}

config.api = {
	url: process.env.API_URL || 'https://api.hasmoremedia.com'
}

module.exports = config;