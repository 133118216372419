/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The DomainWebAlternates model module.
 * @module model/DomainWebAlternates
 * @version 1.0.0
 */
export class DomainWebAlternates {
  /**
   * Constructs a new <code>DomainWebAlternates</code>.
   * @alias module:model/DomainWebAlternates
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>DomainWebAlternates</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DomainWebAlternates} obj Optional instance to populate.
   * @return {module:model/DomainWebAlternates} The populated <code>DomainWebAlternates</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DomainWebAlternates();
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('domain'))
        obj.domain = ApiClient.convertToType(data['domain'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} title
 */
DomainWebAlternates.prototype.title = undefined;

/**
 * @member {String} domain
 */
DomainWebAlternates.prototype.domain = undefined;

