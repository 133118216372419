/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {InlineResponse2002} from '../model/InlineResponse2002';
import {InlineResponse2011} from '../model/InlineResponse2011';
import {KeysWebBody} from '../model/KeysWebBody';
import {WebKeyIdBody} from '../model/WebKeyIdBody';

/**
* WebKey service.
* @module api/WebKeyApi
* @version 1.0.0
*/
export class WebKeyApi {

    /**
    * Constructs a new WebKeyApi. 
    * @alias module:api/WebKeyApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addWebKey operation.
     * @callback moduleapi/WebKeyApi~addWebKeyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2011{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new web key
     * @param {module:model/KeysWebBody} body Web Key
     * @param {module:api/WebKeyApi~addWebKeyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addWebKey(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addWebKey");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2011;

      return this.apiClient.callApi(
        '/keys/web', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteWebKey operation.
     * @callback moduleapi/WebKeyApi~deleteWebKeyCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a web key
     * @param {String} keyId Web Key ID
     * @param {module:api/WebKeyApi~deleteWebKeyCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteWebKey(keyId, callback) {
      
      let postBody = null;
      // verify the required parameter 'keyId' is set
      if (keyId === undefined || keyId === null) {
        throw new Error("Missing the required parameter 'keyId' when calling deleteWebKey");
      }

      let pathParams = {
        'keyId': keyId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/keys/web/{keyId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getWebKey operation.
     * @callback moduleapi/WebKeyApi~getWebKeyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2011{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a web key
     * @param {String} keyId Web Key ID
     * @param {module:api/WebKeyApi~getWebKeyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getWebKey(keyId, callback) {
      
      let postBody = null;
      // verify the required parameter 'keyId' is set
      if (keyId === undefined || keyId === null) {
        throw new Error("Missing the required parameter 'keyId' when calling getWebKey");
      }

      let pathParams = {
        'keyId': keyId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2011;

      return this.apiClient.callApi(
        '/keys/web/{keyId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getWebKeys operation.
     * @callback moduleapi/WebKeyApi~getWebKeysCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2002{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get web keys
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyword Keyword to search title and description
     * @param {Boolean} opts.enabled Enabled
     * @param {Number} opts.limit Number of keys
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/WebKeyApi~getWebKeysCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getWebKeys(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'keyword': opts['keyword'],'enabled': opts['enabled'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2002;

      return this.apiClient.callApi(
        '/keys/web', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateWebKey operation.
     * @callback moduleapi/WebKeyApi~updateWebKeyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2011{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a web key
     * @param {module:model/WebKeyIdBody} body Web Key
     * @param {String} keyId Web Key ID
     * @param {module:api/WebKeyApi~updateWebKeyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateWebKey(body, keyId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateWebKey");
      }
      // verify the required parameter 'keyId' is set
      if (keyId === undefined || keyId === null) {
        throw new Error("Missing the required parameter 'keyId' when calling updateWebKey");
      }

      let pathParams = {
        'keyId': keyId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2011;

      return this.apiClient.callApi(
        '/keys/web/{keyId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}