/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CampaignRunDataSend} from './CampaignRunDataSend';

/**
 * The CampaignRunData model module.
 * @module model/CampaignRunData
 * @version 1.0.0
 */
export class CampaignRunData {
  /**
   * Constructs a new <code>CampaignRunData</code>.
   * @alias module:model/CampaignRunData
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CampaignRunData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CampaignRunData} obj Optional instance to populate.
   * @return {module:model/CampaignRunData} The populated <code>CampaignRunData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CampaignRunData();
      if (data.hasOwnProperty('query'))
        obj.query = ApiClient.convertToType(data['query'], 'String');
      if (data.hasOwnProperty('dataset'))
        obj.dataset = ApiClient.convertToType(data['dataset'], 'String');
      if (data.hasOwnProperty('table'))
        obj.table = ApiClient.convertToType(data['table'], 'String');
      if (data.hasOwnProperty('size'))
        obj.size = ApiClient.convertToType(data['size'], 'Number');
      if (data.hasOwnProperty('send'))
        obj.send = CampaignRunDataSend.constructFromObject(data['send']);
    }
    return obj;
  }
}

/**
 * @member {String} query
 */
CampaignRunData.prototype.query = undefined;

/**
 * @member {String} dataset
 */
CampaignRunData.prototype.dataset = undefined;

/**
 * @member {String} table
 */
CampaignRunData.prototype.table = undefined;

/**
 * @member {Number} size
 */
CampaignRunData.prototype.size = undefined;

/**
 * @member {module:model/CampaignRunDataSend} send
 */
CampaignRunData.prototype.send = undefined;

