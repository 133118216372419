/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AutomationTriggersBody model module.
 * @module model/AutomationTriggersBody
 * @version 1.0.0
 */
export class AutomationTriggersBody {
  /**
   * Constructs a new <code>AutomationTriggersBody</code>.
   * @alias module:model/AutomationTriggersBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AutomationTriggersBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AutomationTriggersBody} obj Optional instance to populate.
   * @return {module:model/AutomationTriggersBody} The populated <code>AutomationTriggersBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AutomationTriggersBody();
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
      if (data.hasOwnProperty('events'))
        obj.events = ApiClient.convertToType(data['events'], ['String']);
      if (data.hasOwnProperty('actions'))
        obj.actions = ApiClient.convertToType(data['actions'], ['String']);
    }
    return obj;
  }
}

/**
 * @member {String} title
 */
AutomationTriggersBody.prototype.title = undefined;

/**
 * @member {String} description
 */
AutomationTriggersBody.prototype.description = undefined;

/**
 * @member {Boolean} enabled
 */
AutomationTriggersBody.prototype.enabled = undefined;

/**
 * @member {Array.<String>} events
 */
AutomationTriggersBody.prototype.events = undefined;

/**
 * @member {Array.<String>} actions
 */
AutomationTriggersBody.prototype.actions = undefined;

