/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {AutomationTriggersBody} from '../model/AutomationTriggersBody';
import {InlineResponse20021} from '../model/InlineResponse20021';
import {InlineResponse20114} from '../model/InlineResponse20114';
import {TriggersTriggerAutomationIdBody} from '../model/TriggersTriggerAutomationIdBody';

/**
* TriggerAutomation service.
* @module api/TriggerAutomationApi
* @version 1.0.0
*/
export class TriggerAutomationApi {

    /**
    * Constructs a new TriggerAutomationApi. 
    * @alias module:api/TriggerAutomationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addTriggerAutomation operation.
     * @callback moduleapi/TriggerAutomationApi~addTriggerAutomationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20114{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new trigger automation
     * @param {module:model/AutomationTriggersBody} body Trigger Automation
     * @param {module:api/TriggerAutomationApi~addTriggerAutomationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addTriggerAutomation(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addTriggerAutomation");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20114;

      return this.apiClient.callApi(
        '/automation/triggers', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteTriggerAutomation operation.
     * @callback moduleapi/TriggerAutomationApi~deleteTriggerAutomationCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a trigger automation
     * @param {String} triggerAutomationId Trigger Automation ID
     * @param {module:api/TriggerAutomationApi~deleteTriggerAutomationCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteTriggerAutomation(triggerAutomationId, callback) {
      
      let postBody = null;
      // verify the required parameter 'triggerAutomationId' is set
      if (triggerAutomationId === undefined || triggerAutomationId === null) {
        throw new Error("Missing the required parameter 'triggerAutomationId' when calling deleteTriggerAutomation");
      }

      let pathParams = {
        'triggerAutomationId': triggerAutomationId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/automation/triggers/{triggerAutomationId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getTriggerAutomation operation.
     * @callback moduleapi/TriggerAutomationApi~getTriggerAutomationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20114{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a trigger automation
     * @param {String} triggerAutomationId Trigger Automation ID
     * @param {module:api/TriggerAutomationApi~getTriggerAutomationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getTriggerAutomation(triggerAutomationId, callback) {
      
      let postBody = null;
      // verify the required parameter 'triggerAutomationId' is set
      if (triggerAutomationId === undefined || triggerAutomationId === null) {
        throw new Error("Missing the required parameter 'triggerAutomationId' when calling getTriggerAutomation");
      }

      let pathParams = {
        'triggerAutomationId': triggerAutomationId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20114;

      return this.apiClient.callApi(
        '/automation/triggers/{triggerAutomationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getTriggerAutomations operation.
     * @callback moduleapi/TriggerAutomationApi~getTriggerAutomationsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20021{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get trigger automations
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyword Keyword search in title and description
     * @param {Number} opts.limit Number of trigger automations
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/TriggerAutomationApi~getTriggerAutomationsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getTriggerAutomations(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'keyword': opts['keyword'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20021;

      return this.apiClient.callApi(
        '/automation/triggers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateTriggerAutomation operation.
     * @callback moduleapi/TriggerAutomationApi~updateTriggerAutomationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20114{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a trigger automation
     * @param {module:model/TriggersTriggerAutomationIdBody} body Trigger Automation
     * @param {String} triggerAutomationId Trigger Automation ID
     * @param {module:api/TriggerAutomationApi~updateTriggerAutomationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateTriggerAutomation(body, triggerAutomationId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateTriggerAutomation");
      }
      // verify the required parameter 'triggerAutomationId' is set
      if (triggerAutomationId === undefined || triggerAutomationId === null) {
        throw new Error("Missing the required parameter 'triggerAutomationId' when calling updateTriggerAutomation");
      }

      let pathParams = {
        'triggerAutomationId': triggerAutomationId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20114;

      return this.apiClient.callApi(
        '/automation/triggers/{triggerAutomationId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}