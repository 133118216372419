/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {DomainWebAlternates} from './DomainWebAlternates';

/**
 * The DomainWeb model module.
 * @module model/DomainWeb
 * @version 1.0.0
 */
export class DomainWeb {
  /**
   * Constructs a new <code>DomainWeb</code>.
   * @alias module:model/DomainWeb
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>DomainWeb</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DomainWeb} obj Optional instance to populate.
   * @return {module:model/DomainWeb} The populated <code>DomainWeb</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DomainWeb();
      if (data.hasOwnProperty('primary'))
        obj.primary = ApiClient.convertToType(data['primary'], 'String');
      if (data.hasOwnProperty('alternates'))
        obj.alternates = ApiClient.convertToType(data['alternates'], [DomainWebAlternates]);
    }
    return obj;
  }
}

/**
 * @member {String} primary
 */
DomainWeb.prototype.primary = undefined;

/**
 * @member {Array.<module:model/DomainWebAlternates>} alternates
 */
DomainWeb.prototype.alternates = undefined;

