/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EventsTriggerAutomationEventIdBody model module.
 * @module model/EventsTriggerAutomationEventIdBody
 * @version 1.0.0
 */
export class EventsTriggerAutomationEventIdBody {
  /**
   * Constructs a new <code>EventsTriggerAutomationEventIdBody</code>.
   * @alias module:model/EventsTriggerAutomationEventIdBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EventsTriggerAutomationEventIdBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EventsTriggerAutomationEventIdBody} obj Optional instance to populate.
   * @return {module:model/EventsTriggerAutomationEventIdBody} The populated <code>EventsTriggerAutomationEventIdBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EventsTriggerAutomationEventIdBody();
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('globalType'))
        obj.globalType = ApiClient.convertToType(data['globalType'], 'String');
      if (data.hasOwnProperty('propertyId'))
        obj.propertyId = ApiClient.convertToType(data['propertyId'], 'String');
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} title
 */
EventsTriggerAutomationEventIdBody.prototype.title = undefined;

/**
 * @member {String} description
 */
EventsTriggerAutomationEventIdBody.prototype.description = undefined;

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
EventsTriggerAutomationEventIdBody.TypeEnum = {
  /**
   * value: "subscribe"
   * @const
   */
  subscribe: "subscribe"
};
/**
 * @member {module:model/EventsTriggerAutomationEventIdBody.TypeEnum} type
 */
EventsTriggerAutomationEventIdBody.prototype.type = undefined;

/**
 * Allowed values for the <code>globalType</code> property.
 * @enum {String}
 * @readonly
 */
EventsTriggerAutomationEventIdBody.GlobalTypeEnum = {
  /**
   * value: "none"
   * @const
   */
  none: "none",

  /**
   * value: "global"
   * @const
   */
  global: "global",

  /**
   * value: "property"
   * @const
   */
  property: "property"
};
/**
 * @member {module:model/EventsTriggerAutomationEventIdBody.GlobalTypeEnum} globalType
 */
EventsTriggerAutomationEventIdBody.prototype.globalType = undefined;

/**
 * @member {String} propertyId
 */
EventsTriggerAutomationEventIdBody.prototype.propertyId = undefined;

/**
 * @member {Boolean} enabled
 */
EventsTriggerAutomationEventIdBody.prototype.enabled = undefined;

