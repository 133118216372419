/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The CampaignSettings model module.
 * @module model/CampaignSettings
 * @version 1.0.0
 */
export class CampaignSettings {
  /**
   * Constructs a new <code>CampaignSettings</code>.
   * @alias module:model/CampaignSettings
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CampaignSettings</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CampaignSettings} obj Optional instance to populate.
   * @return {module:model/CampaignSettings} The populated <code>CampaignSettings</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CampaignSettings();
      if (data.hasOwnProperty('startDate'))
        obj.startDate = ApiClient.convertToType(data['startDate'], 'String');
      if (data.hasOwnProperty('schedule'))
        obj.schedule = ApiClient.convertToType(data['schedule'], 'String');
      if (data.hasOwnProperty('nextDate'))
        obj.nextDate = ApiClient.convertToType(data['nextDate'], 'String');
      if (data.hasOwnProperty('endDate'))
        obj.endDate = ApiClient.convertToType(data['endDate'], 'String');
      if (data.hasOwnProperty('duration'))
        obj.duration = ApiClient.convertToType(data['duration'], 'Number');
      if (data.hasOwnProperty('pattern'))
        obj.pattern = ApiClient.convertToType(data['pattern'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} startDate
 */
CampaignSettings.prototype.startDate = undefined;

/**
 * @member {String} schedule
 */
CampaignSettings.prototype.schedule = undefined;

/**
 * @member {String} nextDate
 */
CampaignSettings.prototype.nextDate = undefined;

/**
 * @member {String} endDate
 */
CampaignSettings.prototype.endDate = undefined;

/**
 * @member {Number} duration
 */
CampaignSettings.prototype.duration = undefined;

/**
 * Allowed values for the <code>pattern</code> property.
 * @enum {String}
 * @readonly
 */
CampaignSettings.PatternEnum = {
  /**
   * value: "linear"
   * @const
   */
  linear: "linear",

  /**
   * value: "random"
   * @const
   */
  random: "random"
};
/**
 * @member {module:model/CampaignSettings.PatternEnum} pattern
 */
CampaignSettings.prototype.pattern = undefined;

