/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AutomationdataconnectionsParams} from './AutomationdataconnectionsParams';

/**
 * The DataAutomationConnection model module.
 * @module model/DataAutomationConnection
 * @version 1.0.0
 */
export class DataAutomationConnection {
  /**
   * Constructs a new <code>DataAutomationConnection</code>.
   * @alias module:model/DataAutomationConnection
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>DataAutomationConnection</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DataAutomationConnection} obj Optional instance to populate.
   * @return {module:model/DataAutomationConnection} The populated <code>DataAutomationConnection</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DataAutomationConnection();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('tags'))
        obj.tags = ApiClient.convertToType(data['tags'], ['String']);
      if (data.hasOwnProperty('params'))
        obj.params = ApiClient.convertToType(data['params'], [AutomationdataconnectionsParams]);
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
DataAutomationConnection.prototype._id = undefined;

/**
 * @member {String} title
 */
DataAutomationConnection.prototype.title = undefined;

/**
 * @member {String} description
 */
DataAutomationConnection.prototype.description = undefined;

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
DataAutomationConnection.TypeEnum = {
  /**
   * value: "mongo"
   * @const
   */
  mongo: "mongo",

  /**
   * value: "bigquery"
   * @const
   */
  bigquery: "bigquery",

  /**
   * value: "csv"
   * @const
   */
  csv: "csv",

  /**
   * value: "cms"
   * @const
   */
  cms: "cms"
};
/**
 * @member {module:model/DataAutomationConnection.TypeEnum} type
 */
DataAutomationConnection.prototype.type = undefined;

/**
 * @member {Array.<String>} tags
 */
DataAutomationConnection.prototype.tags = undefined;

/**
 * @member {Array.<module:model/AutomationdataconnectionsParams>} params
 */
DataAutomationConnection.prototype.params = undefined;

/**
 * @member {String} created
 */
DataAutomationConnection.prototype.created = undefined;

/**
 * @member {String} updated
 */
DataAutomationConnection.prototype.updated = undefined;

