/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {TriggerAutomationAction} from './TriggerAutomationAction';
import {TriggerAutomationEvent} from './TriggerAutomationEvent';

/**
 * The TriggerAutomation model module.
 * @module model/TriggerAutomation
 * @version 1.0.0
 */
export class TriggerAutomation {
  /**
   * Constructs a new <code>TriggerAutomation</code>.
   * @alias module:model/TriggerAutomation
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>TriggerAutomation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TriggerAutomation} obj Optional instance to populate.
   * @return {module:model/TriggerAutomation} The populated <code>TriggerAutomation</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TriggerAutomation();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
      if (data.hasOwnProperty('events'))
        obj.events = ApiClient.convertToType(data['events'], [TriggerAutomationEvent]);
      if (data.hasOwnProperty('actions'))
        obj.actions = ApiClient.convertToType(data['actions'], [TriggerAutomationAction]);
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
TriggerAutomation.prototype._id = undefined;

/**
 * @member {String} title
 */
TriggerAutomation.prototype.title = undefined;

/**
 * @member {String} description
 */
TriggerAutomation.prototype.description = undefined;

/**
 * @member {Boolean} enabled
 */
TriggerAutomation.prototype.enabled = undefined;

/**
 * @member {Array.<module:model/TriggerAutomationEvent>} events
 */
TriggerAutomation.prototype.events = undefined;

/**
 * @member {Array.<module:model/TriggerAutomationAction>} actions
 */
TriggerAutomation.prototype.actions = undefined;

/**
 * @member {String} created
 */
TriggerAutomation.prototype.created = undefined;

/**
 * @member {String} updated
 */
TriggerAutomation.prototype.updated = undefined;

