/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The SegmentsJoinOn model module.
 * @module model/SegmentsJoinOn
 * @version 1.0.0
 */
export class SegmentsJoinOn {
  /**
   * Constructs a new <code>SegmentsJoinOn</code>.
   * @alias module:model/SegmentsJoinOn
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>SegmentsJoinOn</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SegmentsJoinOn} obj Optional instance to populate.
   * @return {module:model/SegmentsJoinOn} The populated <code>SegmentsJoinOn</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SegmentsJoinOn();
      if (data.hasOwnProperty('leftField'))
        obj.leftField = ApiClient.convertToType(data['leftField'], 'String');
      if (data.hasOwnProperty('rightField'))
        obj.rightField = ApiClient.convertToType(data['rightField'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} leftField
 */
SegmentsJoinOn.prototype.leftField = undefined;

/**
 * @member {String} rightField
 */
SegmentsJoinOn.prototype.rightField = undefined;

