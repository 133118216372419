/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EmailProvider model module.
 * @module model/EmailProvider
 * @version 1.0.0
 */
export class EmailProvider {
  /**
   * Constructs a new <code>EmailProvider</code>.
   * @alias module:model/EmailProvider
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailProvider</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailProvider} obj Optional instance to populate.
   * @return {module:model/EmailProvider} The populated <code>EmailProvider</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailProvider();
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('code'))
        obj.code = ApiClient.convertToType(data['code'], 'String');
      if (data.hasOwnProperty('subcode'))
        obj.subcode = ApiClient.convertToType(data['subcode'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} type
 */
EmailProvider.prototype.type = undefined;

/**
 * @member {String} code
 */
EmailProvider.prototype.code = undefined;

/**
 * @member {String} subcode
 */
EmailProvider.prototype.subcode = undefined;

