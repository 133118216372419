/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Segment} from './Segment';

/**
 * The CampaignSegments model module.
 * @module model/CampaignSegments
 * @version 1.0.0
 */
export class CampaignSegments {
  /**
   * Constructs a new <code>CampaignSegments</code>.
   * @alias module:model/CampaignSegments
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CampaignSegments</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CampaignSegments} obj Optional instance to populate.
   * @return {module:model/CampaignSegments} The populated <code>CampaignSegments</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CampaignSegments();
      if (data.hasOwnProperty('inclusions'))
        obj.inclusions = ApiClient.convertToType(data['inclusions'], [Segment]);
      if (data.hasOwnProperty('exclusions'))
        obj.exclusions = ApiClient.convertToType(data['exclusions'], [Segment]);
      if (data.hasOwnProperty('data'))
        obj.data = ApiClient.convertToType(data['data'], [Segment]);
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/Segment>} inclusions
 */
CampaignSegments.prototype.inclusions = undefined;

/**
 * @member {Array.<module:model/Segment>} exclusions
 */
CampaignSegments.prototype.exclusions = undefined;

/**
 * @member {Array.<module:model/Segment>} data
 */
CampaignSegments.prototype.data = undefined;

