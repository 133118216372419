/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Template} from './Template';

/**
 * The CampaignTemplatesInner model module.
 * @module model/CampaignTemplatesInner
 * @version 1.0.0
 */
export class CampaignTemplatesInner {
  /**
   * Constructs a new <code>CampaignTemplatesInner</code>.
   * @alias module:model/CampaignTemplatesInner
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CampaignTemplatesInner</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CampaignTemplatesInner} obj Optional instance to populate.
   * @return {module:model/CampaignTemplatesInner} The populated <code>CampaignTemplatesInner</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CampaignTemplatesInner();
      if (data.hasOwnProperty('template'))
        obj.template = Template.constructFromObject(data['template']);
      if (data.hasOwnProperty('split'))
        obj.split = ApiClient.convertToType(data['split'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {module:model/Template} template
 */
CampaignTemplatesInner.prototype.template = undefined;

/**
 * @member {Number} split
 */
CampaignTemplatesInner.prototype.split = undefined;

