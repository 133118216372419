/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {EmailsCheckResultData} from './EmailsCheckResultData';
import {EmailsCheckResultFiles} from './EmailsCheckResultFiles';

/**
 * The EmailsCheckResult model module.
 * @module model/EmailsCheckResult
 * @version 1.0.0
 */
export class EmailsCheckResult {
  /**
   * Constructs a new <code>EmailsCheckResult</code>.
   * @alias module:model/EmailsCheckResult
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailsCheckResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailsCheckResult} obj Optional instance to populate.
   * @return {module:model/EmailsCheckResult} The populated <code>EmailsCheckResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailsCheckResult();
      if (data.hasOwnProperty('progress'))
        obj.progress = ApiClient.convertToType(data['progress'], 'Number');
      if (data.hasOwnProperty('success'))
        obj.success = ApiClient.convertToType(data['success'], 'Boolean');
      if (data.hasOwnProperty('files'))
        obj.files = EmailsCheckResultFiles.constructFromObject(data['files']);
      if (data.hasOwnProperty('data'))
        obj.data = EmailsCheckResultData.constructFromObject(data['data']);
      if (data.hasOwnProperty('error'))
        obj.error = ApiClient.convertToType(data['error'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} progress
 */
EmailsCheckResult.prototype.progress = undefined;

/**
 * @member {Boolean} success
 */
EmailsCheckResult.prototype.success = undefined;

/**
 * @member {module:model/EmailsCheckResultFiles} files
 */
EmailsCheckResult.prototype.files = undefined;

/**
 * @member {module:model/EmailsCheckResultData} data
 */
EmailsCheckResult.prototype.data = undefined;

/**
 * @member {String} error
 */
EmailsCheckResult.prototype.error = undefined;

