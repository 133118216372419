/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CampaignSettings} from './CampaignSettings';
import {CampaignsSegments} from './CampaignsSegments';
import {CampaignsTemplates} from './CampaignsTemplates';

/**
 * The CampaignsCampaignIdBody model module.
 * @module model/CampaignsCampaignIdBody
 * @version 1.0.0
 */
export class CampaignsCampaignIdBody {
  /**
   * Constructs a new <code>CampaignsCampaignIdBody</code>.
   * @alias module:model/CampaignsCampaignIdBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CampaignsCampaignIdBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CampaignsCampaignIdBody} obj Optional instance to populate.
   * @return {module:model/CampaignsCampaignIdBody} The populated <code>CampaignsCampaignIdBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CampaignsCampaignIdBody();
      if (data.hasOwnProperty('propertyId'))
        obj.propertyId = ApiClient.convertToType(data['propertyId'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
      if (data.hasOwnProperty('templates'))
        obj.templates = ApiClient.convertToType(data['templates'], [CampaignsTemplates]);
      if (data.hasOwnProperty('segments'))
        obj.segments = CampaignsSegments.constructFromObject(data['segments']);
      if (data.hasOwnProperty('espId'))
        obj.espId = ApiClient.convertToType(data['espId'], 'String');
      if (data.hasOwnProperty('settings'))
        obj.settings = CampaignSettings.constructFromObject(data['settings']);
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} propertyId
 */
CampaignsCampaignIdBody.prototype.propertyId = undefined;

/**
 * @member {String} title
 */
CampaignsCampaignIdBody.prototype.title = undefined;

/**
 * @member {String} description
 */
CampaignsCampaignIdBody.prototype.description = undefined;

/**
 * @member {Boolean} enabled
 */
CampaignsCampaignIdBody.prototype.enabled = undefined;

/**
 * @member {Array.<module:model/CampaignsTemplates>} templates
 */
CampaignsCampaignIdBody.prototype.templates = undefined;

/**
 * @member {module:model/CampaignsSegments} segments
 */
CampaignsCampaignIdBody.prototype.segments = undefined;

/**
 * @member {String} espId
 */
CampaignsCampaignIdBody.prototype.espId = undefined;

/**
 * @member {module:model/CampaignSettings} settings
 */
CampaignsCampaignIdBody.prototype.settings = undefined;

/**
 * @member {String} created
 */
CampaignsCampaignIdBody.prototype.created = undefined;

/**
 * @member {String} updated
 */
CampaignsCampaignIdBody.prototype.updated = undefined;

