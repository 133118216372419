/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {CampaignIdRunBody} from '../model/CampaignIdRunBody';
import {CampaignIdTestBody} from '../model/CampaignIdTestBody';
import {CampaignsBody} from '../model/CampaignsBody';
import {CampaignsCampaignIdBody} from '../model/CampaignsCampaignIdBody';
import {InlineResponse20013} from '../model/InlineResponse20013';
import {InlineResponse20015} from '../model/InlineResponse20015';
import {InlineResponse20016} from '../model/InlineResponse20016';
import {InlineResponse20017} from '../model/InlineResponse20017';
import {InlineResponse20018} from '../model/InlineResponse20018';
import {InlineResponse20111} from '../model/InlineResponse20111';

/**
* Campaign service.
* @module api/CampaignApi
* @version 1.0.0
*/
export class CampaignApi {

    /**
    * Constructs a new CampaignApi. 
    * @alias module:api/CampaignApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addCampaign operation.
     * @callback moduleapi/CampaignApi~addCampaignCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20111{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new campaign
     * @param {module:model/CampaignsBody} body Campaign
     * @param {module:api/CampaignApi~addCampaignCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addCampaign(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addCampaign");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20111;

      return this.apiClient.callApi(
        '/campaigns', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteCampaign operation.
     * @callback moduleapi/CampaignApi~deleteCampaignCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a campaign
     * @param {String} campaignId Campaign ID
     * @param {module:api/CampaignApi~deleteCampaignCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteCampaign(campaignId, callback) {
      
      let postBody = null;
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling deleteCampaign");
      }

      let pathParams = {
        'campaignId': campaignId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getCampaign operation.
     * @callback moduleapi/CampaignApi~getCampaignCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20111{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a campaign
     * @param {String} campaignId Campaign ID
     * @param {module:api/CampaignApi~getCampaignCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getCampaign(campaignId, callback) {
      
      let postBody = null;
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling getCampaign");
      }

      let pathParams = {
        'campaignId': campaignId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20111;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getCampaignRunSize operation.
     * @callback moduleapi/CampaignApi~getCampaignRunSizeCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20013{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get campaign run size
     * @param {String} campaignId Campaign id
     * @param {String} campaignRunId Campaign run id
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.send Toggle between total size and send size
     * @param {module:api/CampaignApi~getCampaignRunSizeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getCampaignRunSize(campaignId, campaignRunId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling getCampaignRunSize");
      }
      // verify the required parameter 'campaignRunId' is set
      if (campaignRunId === undefined || campaignRunId === null) {
        throw new Error("Missing the required parameter 'campaignRunId' when calling getCampaignRunSize");
      }

      let pathParams = {
        'campaignId': campaignId,'campaignRunId': campaignRunId
      };
      let queryParams = {
        'send': opts['send']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20013;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}/runs/{campaignRunId}/size', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getCampaignRunStats operation.
     * @callback moduleapi/CampaignApi~getCampaignRunStatsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20018{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get campaign run stats
     * @param {String} campaignId Campaign id
     * @param {String} campaignRunId Campaign run id
     * @param {module:api/CampaignApi~getCampaignRunStatsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getCampaignRunStats(campaignId, campaignRunId, callback) {
      
      let postBody = null;
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling getCampaignRunStats");
      }
      // verify the required parameter 'campaignRunId' is set
      if (campaignRunId === undefined || campaignRunId === null) {
        throw new Error("Missing the required parameter 'campaignRunId' when calling getCampaignRunStats");
      }

      let pathParams = {
        'campaignId': campaignId,'campaignRunId': campaignRunId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20018;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}/runs/{campaignRunId}/stats', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getCampaignRuns operation.
     * @callback moduleapi/CampaignApi~getCampaignRunsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20017{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get campaign runs
     * @param {String} campaignId Campaign id
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.state Template id
     * @param {Number} opts.limit Number of campaign runs
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/CampaignApi~getCampaignRunsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getCampaignRuns(campaignId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling getCampaignRuns");
      }

      let pathParams = {
        'campaignId': campaignId
      };
      let queryParams = {
        'state': opts['state'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20017;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}/runs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getCampaigns operation.
     * @callback moduleapi/CampaignApi~getCampaignsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20015{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get campaigns
     * @param {Object} opts Optional parameters
     * @param {String} opts.propertyId Property id
     * @param {String} opts.templateId Template id
     * @param {String} opts.keyword Keyword search in title and description
     * @param {Boolean} opts.enabled Enabled
     * @param {Number} opts.limit Number of campaigns
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/CampaignApi~getCampaignsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getCampaigns(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'propertyId': opts['propertyId'],'templateId': opts['templateId'],'keyword': opts['keyword'],'enabled': opts['enabled'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20015;

      return this.apiClient.callApi(
        '/campaigns', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getRecentCampaignRuns operation.
     * @callback moduleapi/CampaignApi~getRecentCampaignRunsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20017{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get recent campaign runs
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.campaignIds Campaign Ids
     * @param {module:api/CampaignApi~getRecentCampaignRunsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getRecentCampaignRuns(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'campaignIds': this.apiClient.buildCollectionParam(opts['campaignIds'], 'csv')
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20017;

      return this.apiClient.callApi(
        '/campaigns/runs/recent', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the pauseCampaign operation.
     * @callback moduleapi/CampaignApi~pauseCampaignCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20016{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Pause a campaign
     * @param {String} campaignId Campaign ID
     * @param {module:api/CampaignApi~pauseCampaignCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    pauseCampaign(campaignId, callback) {
      
      let postBody = null;
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling pauseCampaign");
      }

      let pathParams = {
        'campaignId': campaignId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20016;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}/pause', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the processCampaign operation.
     * @callback moduleapi/CampaignApi~processCampaignCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20016{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Process a campaign
     * @param {String} campaignId Campaign ID
     * @param {module:api/CampaignApi~processCampaignCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    processCampaign(campaignId, callback) {
      
      let postBody = null;
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling processCampaign");
      }

      let pathParams = {
        'campaignId': campaignId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20016;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}/process', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the restartCampaign operation.
     * @callback moduleapi/CampaignApi~restartCampaignCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20016{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Restart a failed campaign
     * @param {String} campaignId Campaign ID
     * @param {module:api/CampaignApi~restartCampaignCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    restartCampaign(campaignId, callback) {
      
      let postBody = null;
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling restartCampaign");
      }

      let pathParams = {
        'campaignId': campaignId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20016;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}/restart', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the resumeCampaign operation.
     * @callback moduleapi/CampaignApi~resumeCampaignCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20016{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Resume a campaign
     * @param {String} campaignId Campaign ID
     * @param {module:api/CampaignApi~resumeCampaignCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    resumeCampaign(campaignId, callback) {
      
      let postBody = null;
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling resumeCampaign");
      }

      let pathParams = {
        'campaignId': campaignId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20016;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}/resume', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the runCampaign operation.
     * @callback moduleapi/CampaignApi~runCampaignCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20016{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Run a campaign
     * @param {module:model/CampaignIdRunBody} body Run Options
     * @param {String} campaignId Campaign ID
     * @param {module:api/CampaignApi~runCampaignCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    runCampaign(body, campaignId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling runCampaign");
      }
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling runCampaign");
      }

      let pathParams = {
        'campaignId': campaignId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20016;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}/run', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the stopCampaign operation.
     * @callback moduleapi/CampaignApi~stopCampaignCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20016{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Stop a campaign
     * @param {String} campaignId Campaign ID
     * @param {module:api/CampaignApi~stopCampaignCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    stopCampaign(campaignId, callback) {
      
      let postBody = null;
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling stopCampaign");
      }

      let pathParams = {
        'campaignId': campaignId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20016;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}/stop', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the testCampaign operation.
     * @callback moduleapi/CampaignApi~testCampaignCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20016{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Test a campaign
     * @param {module:model/CampaignIdTestBody} body Run Options
     * @param {String} campaignId Campaign ID
     * @param {module:api/CampaignApi~testCampaignCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    testCampaign(body, campaignId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling testCampaign");
      }
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling testCampaign");
      }

      let pathParams = {
        'campaignId': campaignId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20016;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}/test', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateCampaign operation.
     * @callback moduleapi/CampaignApi~updateCampaignCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20111{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a campaign
     * @param {module:model/CampaignsCampaignIdBody} body Campaign
     * @param {String} campaignId Campaign ID
     * @param {module:api/CampaignApi~updateCampaignCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateCampaign(body, campaignId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateCampaign");
      }
      // verify the required parameter 'campaignId' is set
      if (campaignId === undefined || campaignId === null) {
        throw new Error("Missing the required parameter 'campaignId' when calling updateCampaign");
      }

      let pathParams = {
        'campaignId': campaignId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20111;

      return this.apiClient.callApi(
        '/campaigns/{campaignId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}