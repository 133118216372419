/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ESPSettingsEmails model module.
 * @module model/ESPSettingsEmails
 * @version 1.0.0
 */
export class ESPSettingsEmails {
  /**
   * Constructs a new <code>ESPSettingsEmails</code>.
   * @alias module:model/ESPSettingsEmails
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ESPSettingsEmails</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ESPSettingsEmails} obj Optional instance to populate.
   * @return {module:model/ESPSettingsEmails} The populated <code>ESPSettingsEmails</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ESPSettingsEmails();
      if (data.hasOwnProperty('from'))
        obj.from = ApiClient.convertToType(data['from'], 'String');
      if (data.hasOwnProperty('reply'))
        obj.reply = ApiClient.convertToType(data['reply'], 'String');
      if (data.hasOwnProperty('return'))
        obj.return = ApiClient.convertToType(data['return'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} from
 */
ESPSettingsEmails.prototype.from = undefined;

/**
 * @member {String} reply
 */
ESPSettingsEmails.prototype.reply = undefined;

/**
 * @member {String} return
 */
ESPSettingsEmails.prototype.return = undefined;

