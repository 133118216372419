/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The FileType model module.
 * @module model/FileType
 * @version 1.0.0
 */
export class FileType {
  /**
   * Constructs a new <code>FileType</code>.
   * @alias module:model/FileType
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>FileType</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/FileType} obj Optional instance to populate.
   * @return {module:model/FileType} The populated <code>FileType</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new FileType();
      if (data.hasOwnProperty('path'))
        obj.path = ApiClient.convertToType(data['path'], 'String');
      if (data.hasOwnProperty('url'))
        obj.url = ApiClient.convertToType(data['url'], 'String');
      if (data.hasOwnProperty('mimeType'))
        obj.mimeType = ApiClient.convertToType(data['mimeType'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} path
 */
FileType.prototype.path = undefined;

/**
 * @member {String} url
 */
FileType.prototype.url = undefined;

/**
 * @member {String} mimeType
 */
FileType.prototype.mimeType = undefined;

