/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Campaign} from './Campaign';
import {CampaignRunData} from './CampaignRunData';
import {CampaignRunResult} from './CampaignRunResult';
import {CampaignRunStartDates} from './CampaignRunStartDates';

/**
 * The CampaignRun model module.
 * @module model/CampaignRun
 * @version 1.0.0
 */
export class CampaignRun {
  /**
   * Constructs a new <code>CampaignRun</code>.
   * @alias module:model/CampaignRun
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CampaignRun</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CampaignRun} obj Optional instance to populate.
   * @return {module:model/CampaignRun} The populated <code>CampaignRun</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CampaignRun();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('campaign'))
        obj.campaign = Campaign.constructFromObject(data['campaign']);
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'String');
      if (data.hasOwnProperty('data'))
        obj.data = CampaignRunData.constructFromObject(data['data']);
      if (data.hasOwnProperty('result'))
        obj.result = CampaignRunResult.constructFromObject(data['result']);
      if (data.hasOwnProperty('startDates'))
        obj.startDates = CampaignRunStartDates.constructFromObject(data['startDates']);
      if (data.hasOwnProperty('endDates'))
        obj.endDates = CampaignRunStartDates.constructFromObject(data['endDates']);
      if (data.hasOwnProperty('lock'))
        obj.lock = ApiClient.convertToType(data['lock'], 'Boolean');
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
CampaignRun.prototype._id = undefined;

/**
 * @member {module:model/Campaign} campaign
 */
CampaignRun.prototype.campaign = undefined;

/**
 * Allowed values for the <code>state</code> property.
 * @enum {String}
 * @readonly
 */
CampaignRun.StateEnum = {
  /**
   * value: "started"
   * @const
   */
  started: "started",

  /**
   * value: "processing"
   * @const
   */
  processing: "processing",

  /**
   * value: "ready"
   * @const
   */
  ready: "ready",

  /**
   * value: "running"
   * @const
   */
  running: "running",

  /**
   * value: "paused"
   * @const
   */
  paused: "paused",

  /**
   * value: "stopped"
   * @const
   */
  stopped: "stopped",

  /**
   * value: "done"
   * @const
   */
  done: "done"
};
/**
 * @member {module:model/CampaignRun.StateEnum} state
 */
CampaignRun.prototype.state = undefined;

/**
 * @member {module:model/CampaignRunData} data
 */
CampaignRun.prototype.data = undefined;

/**
 * @member {module:model/CampaignRunResult} result
 */
CampaignRun.prototype.result = undefined;

/**
 * @member {module:model/CampaignRunStartDates} startDates
 */
CampaignRun.prototype.startDates = undefined;

/**
 * @member {module:model/CampaignRunStartDates} endDates
 */
CampaignRun.prototype.endDates = undefined;

/**
 * @member {Boolean} lock
 */
CampaignRun.prototype.lock = undefined;

/**
 * @member {String} created
 */
CampaignRun.prototype.created = undefined;

/**
 * @member {String} updated
 */
CampaignRun.prototype.updated = undefined;

