/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AutomationdataconnectionsParams model module.
 * @module model/AutomationdataconnectionsParams
 * @version 1.0.0
 */
export class AutomationdataconnectionsParams {
  /**
   * Constructs a new <code>AutomationdataconnectionsParams</code>.
   * @alias module:model/AutomationdataconnectionsParams
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AutomationdataconnectionsParams</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AutomationdataconnectionsParams} obj Optional instance to populate.
   * @return {module:model/AutomationdataconnectionsParams} The populated <code>AutomationdataconnectionsParams</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AutomationdataconnectionsParams();
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('value'))
        obj.value = ApiClient.convertToType(data['value'], 'String');
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>name</code> property.
 * @enum {String}
 * @readonly
 */
AutomationdataconnectionsParams.NameEnum = {
  /**
   * value: "url"
   * @const
   */
  url: "url"
};
/**
 * @member {module:model/AutomationdataconnectionsParams.NameEnum} name
 */
AutomationdataconnectionsParams.prototype.name = undefined;

/**
 * @member {String} value
 */
AutomationdataconnectionsParams.prototype.value = undefined;

