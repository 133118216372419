/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {InlineResponse2003} from '../model/InlineResponse2003';
import {InlineResponse2012} from '../model/InlineResponse2012';
import {KeysPartnerBody} from '../model/KeysPartnerBody';
import {PartnerKeyIdBody} from '../model/PartnerKeyIdBody';

/**
* PartnerKey service.
* @module api/PartnerKeyApi
* @version 1.0.0
*/
export class PartnerKeyApi {

    /**
    * Constructs a new PartnerKeyApi. 
    * @alias module:api/PartnerKeyApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addPartnerKey operation.
     * @callback moduleapi/PartnerKeyApi~addPartnerKeyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2012{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new partner key
     * @param {module:model/KeysPartnerBody} body Partner Key
     * @param {module:api/PartnerKeyApi~addPartnerKeyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addPartnerKey(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addPartnerKey");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2012;

      return this.apiClient.callApi(
        '/keys/partner', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deletePartnerKey operation.
     * @callback moduleapi/PartnerKeyApi~deletePartnerKeyCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a partner key
     * @param {String} keyId Partner Key ID
     * @param {module:api/PartnerKeyApi~deletePartnerKeyCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deletePartnerKey(keyId, callback) {
      
      let postBody = null;
      // verify the required parameter 'keyId' is set
      if (keyId === undefined || keyId === null) {
        throw new Error("Missing the required parameter 'keyId' when calling deletePartnerKey");
      }

      let pathParams = {
        'keyId': keyId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/keys/partner/{keyId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getPartnerKey operation.
     * @callback moduleapi/PartnerKeyApi~getPartnerKeyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2012{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a partner key
     * @param {String} keyId Partner Key ID
     * @param {module:api/PartnerKeyApi~getPartnerKeyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getPartnerKey(keyId, callback) {
      
      let postBody = null;
      // verify the required parameter 'keyId' is set
      if (keyId === undefined || keyId === null) {
        throw new Error("Missing the required parameter 'keyId' when calling getPartnerKey");
      }

      let pathParams = {
        'keyId': keyId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2012;

      return this.apiClient.callApi(
        '/keys/partner/{keyId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getPartnerKeys operation.
     * @callback moduleapi/PartnerKeyApi~getPartnerKeysCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2003{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get partner keys
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyword Keyword to search title and description
     * @param {Boolean} opts.enabled Enabled
     * @param {Number} opts.limit Number of keys
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/PartnerKeyApi~getPartnerKeysCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getPartnerKeys(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'keyword': opts['keyword'],'enabled': opts['enabled'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2003;

      return this.apiClient.callApi(
        '/keys/partner', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updatePartnerKey operation.
     * @callback moduleapi/PartnerKeyApi~updatePartnerKeyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2012{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a partner key
     * @param {module:model/PartnerKeyIdBody} body Partner Key
     * @param {String} keyId Partner Key ID
     * @param {module:api/PartnerKeyApi~updatePartnerKeyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updatePartnerKey(body, keyId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updatePartnerKey");
      }
      // verify the required parameter 'keyId' is set
      if (keyId === undefined || keyId === null) {
        throw new Error("Missing the required parameter 'keyId' when calling updatePartnerKey");
      }

      let pathParams = {
        'keyId': keyId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2012;

      return this.apiClient.callApi(
        '/keys/partner/{keyId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}