/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EmailActivitySubscriptionSource model module.
 * @module model/EmailActivitySubscriptionSource
 * @version 1.0.0
 */
export class EmailActivitySubscriptionSource {
  /**
   * Constructs a new <code>EmailActivitySubscriptionSource</code>.
   * @alias module:model/EmailActivitySubscriptionSource
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailActivitySubscriptionSource</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailActivitySubscriptionSource} obj Optional instance to populate.
   * @return {module:model/EmailActivitySubscriptionSource} The populated <code>EmailActivitySubscriptionSource</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailActivitySubscriptionSource();
      if (data.hasOwnProperty('ip'))
        obj.ip = ApiClient.convertToType(data['ip'], 'String');
      if (data.hasOwnProperty('url'))
        obj.url = ApiClient.convertToType(data['url'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('label'))
        obj.label = ApiClient.convertToType(data['label'], 'String');
      if (data.hasOwnProperty('action'))
        obj.action = ApiClient.convertToType(data['action'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} ip
 */
EmailActivitySubscriptionSource.prototype.ip = undefined;

/**
 * @member {String} url
 */
EmailActivitySubscriptionSource.prototype.url = undefined;

/**
 * @member {String} type
 */
EmailActivitySubscriptionSource.prototype.type = undefined;

/**
 * @member {String} label
 */
EmailActivitySubscriptionSource.prototype.label = undefined;

/**
 * @member {String} action
 */
EmailActivitySubscriptionSource.prototype.action = undefined;

