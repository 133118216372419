/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The QuestionAnswerAnswer model module.
 * @module model/QuestionAnswerAnswer
 * @version 1.0.0
 */
export class QuestionAnswerAnswer {
  /**
   * Constructs a new <code>QuestionAnswerAnswer</code>.
   * @alias module:model/QuestionAnswerAnswer
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>QuestionAnswerAnswer</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/QuestionAnswerAnswer} obj Optional instance to populate.
   * @return {module:model/QuestionAnswerAnswer} The populated <code>QuestionAnswerAnswer</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new QuestionAnswerAnswer();
      if (data.hasOwnProperty('index'))
        obj.index = ApiClient.convertToType(data['index'], 'Number');
      if (data.hasOwnProperty('option'))
        obj.option = ApiClient.convertToType(data['option'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} index
 */
QuestionAnswerAnswer.prototype.index = undefined;

/**
 * @member {String} option
 */
QuestionAnswerAnswer.prototype.option = undefined;

