/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ESPSettingsAccess} from './ESPSettingsAccess';
import {ESPSettingsCustom} from './ESPSettingsCustom';
import {ESPSettingsDomains} from './ESPSettingsDomains';
import {ESPSettingsEmails} from './ESPSettingsEmails';

/**
 * The ESPSettings model module.
 * @module model/ESPSettings
 * @version 1.0.0
 */
export class ESPSettings {
  /**
   * Constructs a new <code>ESPSettings</code>.
   * @alias module:model/ESPSettings
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ESPSettings</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ESPSettings} obj Optional instance to populate.
   * @return {module:model/ESPSettings} The populated <code>ESPSettings</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ESPSettings();
      if (data.hasOwnProperty('access'))
        obj.access = ESPSettingsAccess.constructFromObject(data['access']);
      if (data.hasOwnProperty('emails'))
        obj.emails = ESPSettingsEmails.constructFromObject(data['emails']);
      if (data.hasOwnProperty('domains'))
        obj.domains = ESPSettingsDomains.constructFromObject(data['domains']);
      if (data.hasOwnProperty('custom'))
        obj.custom = ApiClient.convertToType(data['custom'], [ESPSettingsCustom]);
    }
    return obj;
  }
}

/**
 * @member {module:model/ESPSettingsAccess} access
 */
ESPSettings.prototype.access = undefined;

/**
 * @member {module:model/ESPSettingsEmails} emails
 */
ESPSettings.prototype.emails = undefined;

/**
 * @member {module:model/ESPSettingsDomains} domains
 */
ESPSettings.prototype.domains = undefined;

/**
 * @member {Array.<module:model/ESPSettingsCustom>} custom
 */
ESPSettings.prototype.custom = undefined;

