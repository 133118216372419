/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AutomationtriggersactionsData} from './AutomationtriggersactionsData';
import {AutomationtriggersactionsParams} from './AutomationtriggersactionsParams';

/**
 * The TriggerAutomationAction model module.
 * @module model/TriggerAutomationAction
 * @version 1.0.0
 */
export class TriggerAutomationAction {
  /**
   * Constructs a new <code>TriggerAutomationAction</code>.
   * @alias module:model/TriggerAutomationAction
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>TriggerAutomationAction</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TriggerAutomationAction} obj Optional instance to populate.
   * @return {module:model/TriggerAutomationAction} The populated <code>TriggerAutomationAction</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TriggerAutomationAction();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
      if (data.hasOwnProperty('params'))
        obj.params = ApiClient.convertToType(data['params'], [AutomationtriggersactionsParams]);
      if (data.hasOwnProperty('data'))
        obj.data = AutomationtriggersactionsData.constructFromObject(data['data']);
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
TriggerAutomationAction.prototype._id = undefined;

/**
 * @member {String} title
 */
TriggerAutomationAction.prototype.title = undefined;

/**
 * @member {String} description
 */
TriggerAutomationAction.prototype.description = undefined;

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
TriggerAutomationAction.TypeEnum = {
  /**
   * value: "send-email"
   * @const
   */
  send_email: "send-email"
};
/**
 * @member {module:model/TriggerAutomationAction.TypeEnum} type
 */
TriggerAutomationAction.prototype.type = undefined;

/**
 * @member {Boolean} enabled
 */
TriggerAutomationAction.prototype.enabled = undefined;

/**
 * @member {Array.<module:model/AutomationtriggersactionsParams>} params
 */
TriggerAutomationAction.prototype.params = undefined;

/**
 * @member {module:model/AutomationtriggersactionsData} data
 */
TriggerAutomationAction.prototype.data = undefined;

/**
 * @member {String} created
 */
TriggerAutomationAction.prototype.created = undefined;

/**
 * @member {String} updated
 */
TriggerAutomationAction.prototype.updated = undefined;

