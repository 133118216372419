/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {EmailsCheckFieldsMap} from './EmailsCheckFieldsMap';
import {EmailsCheckResult} from './EmailsCheckResult';
import {EmailsCheckSettings} from './EmailsCheckSettings';
import {FileType} from './FileType';

/**
 * The EmailsCheck model module.
 * @module model/EmailsCheck
 * @version 1.0.0
 */
export class EmailsCheck {
  /**
   * Constructs a new <code>EmailsCheck</code>.
   * @alias module:model/EmailsCheck
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailsCheck</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailsCheck} obj Optional instance to populate.
   * @return {module:model/EmailsCheck} The populated <code>EmailsCheck</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailsCheck();
      if (data.hasOwnProperty('_id'))
        obj._id = ApiClient.convertToType(data['_id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('file'))
        obj.file = FileType.constructFromObject(data['file']);
      if (data.hasOwnProperty('fieldsMap'))
        obj.fieldsMap = EmailsCheckFieldsMap.constructFromObject(data['fieldsMap']);
      if (data.hasOwnProperty('settings'))
        obj.settings = EmailsCheckSettings.constructFromObject(data['settings']);
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'String');
      if (data.hasOwnProperty('result'))
        obj.result = EmailsCheckResult.constructFromObject(data['result']);
      if (data.hasOwnProperty('startDate'))
        obj.startDate = ApiClient.convertToType(data['startDate'], 'String');
      if (data.hasOwnProperty('endDate'))
        obj.endDate = ApiClient.convertToType(data['endDate'], 'String');
      if (data.hasOwnProperty('created'))
        obj.created = ApiClient.convertToType(data['created'], 'String');
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} _id
 */
EmailsCheck.prototype._id = undefined;

/**
 * @member {String} title
 */
EmailsCheck.prototype.title = undefined;

/**
 * @member {module:model/FileType} file
 */
EmailsCheck.prototype.file = undefined;

/**
 * @member {module:model/EmailsCheckFieldsMap} fieldsMap
 */
EmailsCheck.prototype.fieldsMap = undefined;

/**
 * @member {module:model/EmailsCheckSettings} settings
 */
EmailsCheck.prototype.settings = undefined;

/**
 * Allowed values for the <code>state</code> property.
 * @enum {String}
 * @readonly
 */
EmailsCheck.StateEnum = {
  /**
   * value: "pending"
   * @const
   */
  pending: "pending",

  /**
   * value: "checking"
   * @const
   */
  checking: "checking",

  /**
   * value: "done"
   * @const
   */
  done: "done"
};
/**
 * @member {module:model/EmailsCheck.StateEnum} state
 */
EmailsCheck.prototype.state = undefined;

/**
 * @member {module:model/EmailsCheckResult} result
 */
EmailsCheck.prototype.result = undefined;

/**
 * @member {String} startDate
 */
EmailsCheck.prototype.startDate = undefined;

/**
 * @member {String} endDate
 */
EmailsCheck.prototype.endDate = undefined;

/**
 * @member {String} created
 */
EmailsCheck.prototype.created = undefined;

/**
 * @member {String} updated
 */
EmailsCheck.prototype.updated = undefined;

