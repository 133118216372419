/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {EmailActivitySubscriptionOption} from './EmailActivitySubscriptionOption';
import {EmailActivitySubscriptionSource} from './EmailActivitySubscriptionSource';
import {EmailProfile} from './EmailProfile';
import {EmailProvider} from './EmailProvider';

/**
 * The EmailsSubscribeBody model module.
 * @module model/EmailsSubscribeBody
 * @version 1.0.0
 */
export class EmailsSubscribeBody {
  /**
   * Constructs a new <code>EmailsSubscribeBody</code>.
   * @alias module:model/EmailsSubscribeBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailsSubscribeBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailsSubscribeBody} obj Optional instance to populate.
   * @return {module:model/EmailsSubscribeBody} The populated <code>EmailsSubscribeBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailsSubscribeBody();
      if (data.hasOwnProperty('propertyId'))
        obj.propertyId = ApiClient.convertToType(data['propertyId'], 'String');
      if (data.hasOwnProperty('emailId'))
        obj.emailId = ApiClient.convertToType(data['emailId'], 'String');
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('profile'))
        obj.profile = EmailProfile.constructFromObject(data['profile']);
      if (data.hasOwnProperty('provider'))
        obj.provider = EmailProvider.constructFromObject(data['provider']);
      if (data.hasOwnProperty('source'))
        obj.source = EmailActivitySubscriptionSource.constructFromObject(data['source']);
      if (data.hasOwnProperty('options'))
        obj.options = ApiClient.convertToType(data['options'], [EmailActivitySubscriptionOption]);
    }
    return obj;
  }
}

/**
 * @member {String} propertyId
 */
EmailsSubscribeBody.prototype.propertyId = undefined;

/**
 * @member {String} emailId
 */
EmailsSubscribeBody.prototype.emailId = undefined;

/**
 * @member {String} email
 */
EmailsSubscribeBody.prototype.email = undefined;

/**
 * @member {module:model/EmailProfile} profile
 */
EmailsSubscribeBody.prototype.profile = undefined;

/**
 * @member {module:model/EmailProvider} provider
 */
EmailsSubscribeBody.prototype.provider = undefined;

/**
 * @member {module:model/EmailActivitySubscriptionSource} source
 */
EmailsSubscribeBody.prototype.source = undefined;

/**
 * @member {Array.<module:model/EmailActivitySubscriptionOption>} options
 */
EmailsSubscribeBody.prototype.options = undefined;

