/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {EmailsImportResultData} from './EmailsImportResultData';

/**
 * The EmailsImportResult model module.
 * @module model/EmailsImportResult
 * @version 1.0.0
 */
export class EmailsImportResult {
  /**
   * Constructs a new <code>EmailsImportResult</code>.
   * @alias module:model/EmailsImportResult
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailsImportResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailsImportResult} obj Optional instance to populate.
   * @return {module:model/EmailsImportResult} The populated <code>EmailsImportResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailsImportResult();
      if (data.hasOwnProperty('progress'))
        obj.progress = ApiClient.convertToType(data['progress'], 'Number');
      if (data.hasOwnProperty('success'))
        obj.success = ApiClient.convertToType(data['success'], 'Boolean');
      if (data.hasOwnProperty('data'))
        obj.data = EmailsImportResultData.constructFromObject(data['data']);
      if (data.hasOwnProperty('error'))
        obj.error = ApiClient.convertToType(data['error'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} progress
 */
EmailsImportResult.prototype.progress = undefined;

/**
 * @member {Boolean} success
 */
EmailsImportResult.prototype.success = undefined;

/**
 * @member {module:model/EmailsImportResultData} data
 */
EmailsImportResult.prototype.data = undefined;

/**
 * @member {String} error
 */
EmailsImportResult.prototype.error = undefined;

