/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {InlineResponse20014} from '../model/InlineResponse20014';
import {InlineResponse20110} from '../model/InlineResponse20110';
import {TemplatesBody} from '../model/TemplatesBody';
import {TemplatesTemplateIdBody} from '../model/TemplatesTemplateIdBody';

/**
* Template service.
* @module api/TemplateApi
* @version 1.0.0
*/
export class TemplateApi {

    /**
    * Constructs a new TemplateApi. 
    * @alias module:api/TemplateApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addTemplate operation.
     * @callback moduleapi/TemplateApi~addTemplateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20110{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new template
     * @param {module:model/TemplatesBody} body Template
     * @param {module:api/TemplateApi~addTemplateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addTemplate(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addTemplate");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20110;

      return this.apiClient.callApi(
        '/templates', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteTemplate operation.
     * @callback moduleapi/TemplateApi~deleteTemplateCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a template
     * @param {String} templateId Template ID
     * @param {module:api/TemplateApi~deleteTemplateCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteTemplate(templateId, callback) {
      
      let postBody = null;
      // verify the required parameter 'templateId' is set
      if (templateId === undefined || templateId === null) {
        throw new Error("Missing the required parameter 'templateId' when calling deleteTemplate");
      }

      let pathParams = {
        'templateId': templateId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/templates/{templateId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getTemplate operation.
     * @callback moduleapi/TemplateApi~getTemplateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20110{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a template
     * @param {String} templateId Template ID
     * @param {module:api/TemplateApi~getTemplateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getTemplate(templateId, callback) {
      
      let postBody = null;
      // verify the required parameter 'templateId' is set
      if (templateId === undefined || templateId === null) {
        throw new Error("Missing the required parameter 'templateId' when calling getTemplate");
      }

      let pathParams = {
        'templateId': templateId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20110;

      return this.apiClient.callApi(
        '/templates/{templateId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getTemplates operation.
     * @callback moduleapi/TemplateApi~getTemplatesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20014{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get templates
     * @param {Object} opts Optional parameters
     * @param {String} opts.propertyId Property id
     * @param {String} opts.keyword Keyword search in title and description
     * @param {Number} opts.limit Number of templates
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/TemplateApi~getTemplatesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getTemplates(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'propertyId': opts['propertyId'],'keyword': opts['keyword'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20014;

      return this.apiClient.callApi(
        '/templates', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateTemplate operation.
     * @callback moduleapi/TemplateApi~updateTemplateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20110{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a template
     * @param {module:model/TemplatesTemplateIdBody} body Template
     * @param {String} templateId Template ID
     * @param {module:api/TemplateApi~updateTemplateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateTemplate(body, templateId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateTemplate");
      }
      // verify the required parameter 'templateId' is set
      if (templateId === undefined || templateId === null) {
        throw new Error("Missing the required parameter 'templateId' when calling updateTemplate");
      }

      let pathParams = {
        'templateId': templateId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20110;

      return this.apiClient.callApi(
        '/templates/{templateId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}