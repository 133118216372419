/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AutomationdataSettings} from './AutomationdataSettings';

/**
 * The PipelinesDataAutomationPipelineIdBody model module.
 * @module model/PipelinesDataAutomationPipelineIdBody
 * @version 1.0.0
 */
export class PipelinesDataAutomationPipelineIdBody {
  /**
   * Constructs a new <code>PipelinesDataAutomationPipelineIdBody</code>.
   * @alias module:model/PipelinesDataAutomationPipelineIdBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PipelinesDataAutomationPipelineIdBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PipelinesDataAutomationPipelineIdBody} obj Optional instance to populate.
   * @return {module:model/PipelinesDataAutomationPipelineIdBody} The populated <code>PipelinesDataAutomationPipelineIdBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PipelinesDataAutomationPipelineIdBody();
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('tags'))
        obj.tags = ApiClient.convertToType(data['tags'], ['String']);
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
      if (data.hasOwnProperty('dataAutomations'))
        obj.dataAutomations = ApiClient.convertToType(data['dataAutomations'], ['String']);
      if (data.hasOwnProperty('settings'))
        obj.settings = AutomationdataSettings.constructFromObject(data['settings']);
    }
    return obj;
  }
}

/**
 * @member {String} title
 */
PipelinesDataAutomationPipelineIdBody.prototype.title = undefined;

/**
 * @member {String} description
 */
PipelinesDataAutomationPipelineIdBody.prototype.description = undefined;

/**
 * @member {Array.<String>} tags
 */
PipelinesDataAutomationPipelineIdBody.prototype.tags = undefined;

/**
 * @member {Boolean} enabled
 */
PipelinesDataAutomationPipelineIdBody.prototype.enabled = undefined;

/**
 * @member {Array.<String>} dataAutomations
 */
PipelinesDataAutomationPipelineIdBody.prototype.dataAutomations = undefined;

/**
 * @member {module:model/AutomationdataSettings} settings
 */
PipelinesDataAutomationPipelineIdBody.prototype.settings = undefined;

