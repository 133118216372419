/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AutomationdataconnectionsParams} from './AutomationdataconnectionsParams';

/**
 * The DataConnectionsBody model module.
 * @module model/DataConnectionsBody
 * @version 1.0.0
 */
export class DataConnectionsBody {
  /**
   * Constructs a new <code>DataConnectionsBody</code>.
   * @alias module:model/DataConnectionsBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>DataConnectionsBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DataConnectionsBody} obj Optional instance to populate.
   * @return {module:model/DataConnectionsBody} The populated <code>DataConnectionsBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DataConnectionsBody();
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('tags'))
        obj.tags = ApiClient.convertToType(data['tags'], ['String']);
      if (data.hasOwnProperty('params'))
        obj.params = ApiClient.convertToType(data['params'], [AutomationdataconnectionsParams]);
    }
    return obj;
  }
}

/**
 * @member {String} title
 */
DataConnectionsBody.prototype.title = undefined;

/**
 * @member {String} description
 */
DataConnectionsBody.prototype.description = undefined;

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
DataConnectionsBody.TypeEnum = {
  /**
   * value: "mongo"
   * @const
   */
  mongo: "mongo",

  /**
   * value: "bigquery"
   * @const
   */
  bigquery: "bigquery",

  /**
   * value: "csv"
   * @const
   */
  csv: "csv",

  /**
   * value: "cms"
   * @const
   */
  cms: "cms"
};
/**
 * @member {module:model/DataConnectionsBody.TypeEnum} type
 */
DataConnectionsBody.prototype.type = undefined;

/**
 * @member {Array.<String>} tags
 */
DataConnectionsBody.prototype.tags = undefined;

/**
 * @member {Array.<module:model/AutomationdataconnectionsParams>} params
 */
DataConnectionsBody.prototype.params = undefined;

