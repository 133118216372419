/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {EmailActivityStatus} from './EmailActivityStatus';
import {EmailActivitySubscription} from './EmailActivitySubscription';

/**
 * The ActivitiesEmailActivityIdBody model module.
 * @module model/ActivitiesEmailActivityIdBody
 * @version 1.0.0
 */
export class ActivitiesEmailActivityIdBody {
  /**
   * Constructs a new <code>ActivitiesEmailActivityIdBody</code>.
   * @alias module:model/ActivitiesEmailActivityIdBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ActivitiesEmailActivityIdBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ActivitiesEmailActivityIdBody} obj Optional instance to populate.
   * @return {module:model/ActivitiesEmailActivityIdBody} The populated <code>ActivitiesEmailActivityIdBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ActivitiesEmailActivityIdBody();
      if (data.hasOwnProperty('propertyId'))
        obj.propertyId = ApiClient.convertToType(data['propertyId'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = EmailActivityStatus.constructFromObject(data['status']);
      if (data.hasOwnProperty('subscription'))
        obj.subscription = EmailActivitySubscription.constructFromObject(data['subscription']);
    }
    return obj;
  }
}

/**
 * @member {String} propertyId
 */
ActivitiesEmailActivityIdBody.prototype.propertyId = undefined;

/**
 * @member {module:model/EmailActivityStatus} status
 */
ActivitiesEmailActivityIdBody.prototype.status = undefined;

/**
 * @member {module:model/EmailActivitySubscription} subscription
 */
ActivitiesEmailActivityIdBody.prototype.subscription = undefined;

