/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EmailsSuppressBody model module.
 * @module model/EmailsSuppressBody
 * @version 1.0.0
 */
export class EmailsSuppressBody {
  /**
   * Constructs a new <code>EmailsSuppressBody</code>.
   * @alias module:model/EmailsSuppressBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailsSuppressBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailsSuppressBody} obj Optional instance to populate.
   * @return {module:model/EmailsSuppressBody} The populated <code>EmailsSuppressBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailsSuppressBody();
      if (data.hasOwnProperty('propertyId'))
        obj.propertyId = ApiClient.convertToType(data['propertyId'], 'String');
      if (data.hasOwnProperty('emailId'))
        obj.emailId = ApiClient.convertToType(data['emailId'], 'String');
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} propertyId
 */
EmailsSuppressBody.prototype.propertyId = undefined;

/**
 * @member {String} emailId
 */
EmailsSuppressBody.prototype.emailId = undefined;

/**
 * @member {String} email
 */
EmailsSuppressBody.prototype.email = undefined;

/**
 * Allowed values for the <code>state</code> property.
 * @enum {String}
 * @readonly
 */
EmailsSuppressBody.StateEnum = {
  /**
   * value: "hardBounced"
   * @const
   */
  hardBounced: "hardBounced",

  /**
   * value: "complained"
   * @const
   */
  complained: "complained",

  /**
   * value: "screamed"
   * @const
   */
  screamed: "screamed",

  /**
   * value: "invalid"
   * @const
   */
  invalid: "invalid"
};
/**
 * @member {module:model/EmailsSuppressBody.StateEnum} state
 */
EmailsSuppressBody.prototype.state = undefined;

